import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { Link } from "react-router-dom";
import { ModuleEncartListMt, tools } from "@mitim/react-mitim";
import "./Home.scss";

import { getKeyValuePublique } from "../Constants";
import LogoCDJ from "../../resources/logoCDJ.js";
import { Nouveaute } from "./Nouveaute";

const ELEMENTS_ENCARTS_1 = [
	{
		titre: "AVEC DES CRIS DE JOIE",
		couleurTitre: tools.BLEU_TURQUOISE,
		theme: "rouge",
		imgSrc: "avecDesCrisDeJoie.jpg",
		lireLaSuiteHidden: true,
		margeBasHidden: true,
		nonCliquable: true,
		hoverHidden: true
	},
	{
		titre: "CE QUE NOS PÈRES CHANTAIENT",
		couleurTitre: tools.ORANGE,
		theme: "rouge",
		imgSrc: "ceQueNosPeresChantaient.jpg",
		lireLaSuiteHidden: true,
		margeBasHidden: true,
		nonCliquable: true,
		hoverHidden: true
	},
	{
		titre: "LES LIVES",
		couleurTitre: tools.BLEU,
		theme: "rouge",
		imgSrc: "live.jpg",
		lireLaSuiteHidden: true,
		margeBasHidden: true,
		nonCliquable: true,
		hoverHidden: true
	},
	{
		titre: "LES ENFANTS CHANTENT",
		couleurTitre: tools.MARRON,
		theme: "rouge",
		imgSrc: "enfants.jpg",
		lireLaSuiteHidden: true,
		margeBasHidden: true,
		nonCliquable: true,
		hoverHidden: true
	}
];

class Home extends Page {
	componentDidMount() {
		getKeyValuePublique("site-mission-a-la-une", val => {
			this.setState({
				elementsALaUne: val
			});
		});
	}

	state = {
		elementsALaUne: []
	};

	nouveauteApplicationMobile() {
		return (
			<>
				<div
					className="container-fluid"
					style={{ minHeight: "450px", backgroundColor: "#ebebeb" }}
				>
					<div className="row">
						<div
							className="col-1 d-none d-lg-block"
							style={{ minHeight: "450px" }}
						>
							<div
								className="tourne3"
								style={{
									height: "150px",
									width: "450px",
									marginLeft: "-173px"
								}}
							>
								<br />
								<br />
								<h2
									style={{ color: "white", fontSize: "3em" }}
									className="text-center"
								>
									NOUVEAUTÉ
								</h2>
							</div>
						</div>
						<div className="col-lg-11 col-md-12" style={{ minHeight: "450px" }}>
							<div className="container">
								<Link
									to="/application-mobile"
									className="row home-block-link nouveaute"
								>
									<div className="block col-xl-6 col-md-5 col-sm-12 mt-3 mb-3">
										<div className="pt-3 mt-2">
											<img
												src={`${tools.lienPhotos}/ApplicationMobile.png`}
												className="img-fluid w-100"
												alt=""
												style={{
													height: "380px",
													objectFit: "contain",
													filter: "drop-shadow(-20px 30px 25px grey)"
												}}
											/>
										</div>
									</div>
									<div className="block col-xl-6 col-md-7 col-sm-12 mt-3 mb-3">
										<div className="mt-4">
											<div className="text-center pb-5">
												<div
													style={{
														backgroundColor: "white",
														width: "150px",
														margin: "auto",
														borderRadius: "32px",
														boxShadow: "0px 2px 6px #aaa"
													}}
												>
													<LogoCDJ width={150} />
												</div>

												<h2
													className="mt-4 ft-lato-light"
													style={{ color: "black", fontSize: "1.5rem" }}
												>
													Téléchargez l'application{" "}
													<span style={{ color: "#eeb755" }}>
														Avec des Cris de Joie
													</span>
												</h2>

												<h3
													className="my-2 ft-lato-light mb-4"
													style={{ color: "black", fontSize: "1.2rem" }}
												>
													pour votre téléphone Iphone, Ipad et Android
												</h3>
												<img
													src={`${tools.lienPhotos}/app-android.png`}
													className="img-fluid"
													style={{
														height: "50px",
														objectFit: "contain",
														marginLeft: "10px"
													}}
												/>
												<img
													src={`${tools.lienPhotos}/app-iphone.png`}
													className="img-fluid"
													alt=""
													style={{
														height: "50px",
														objectFit: "contain",
														marginLeft: "10px"
													}}
												/>
											</div>
										</div>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	nouveauteCdEnfants() {
		return (
			<>
				<div
					className="container-fluid bg-blue-light"
					style={{ minHeight: "600px" }}
				>
					<div className="row">
						<div
							className="col-1 d-none d-lg-block"
							style={{ minHeight: "600px" }}
						>
							<div
								className="tourne2"
								style={{
									height: "150px",
									width: "600px",
									marginLeft: "-248px"
								}}
							>
								<br />
								<br />
								<h2
									style={{ color: "#9fc4d7", fontSize: "3em" }}
									className="text-center"
								>
									NOUVEAUTÉ
								</h2>
							</div>
						</div>
						<div className="col-lg-11 col-md-12" style={{ minHeight: "600px" }}>
							<div className="container">
								<Link
									to="/media/1384/Tes-œuvres-te-louent"
									className="row home-block-link nouveaute"
								>
									<div className="block col-xl-5 col-md-5 col-sm-12 mt-3 mb-3">
										<div className="pt-5 mt-2">
											<img
												src={`${tools.lienPhotos}/TesOeuvresTeLouent.png`}
												className="img-fluid w-100"
												alt=""
											/>
										</div>
									</div>
									<div className="block col-xl-7 col-md-7 col-sm-12 mt-3 mb-3">
										<div className="mt-5">
											<h2
												className="mt-2"
												style={{ color: "white", fontSize: "2rem" }}
											>
												16 CHANTS D’ENFANTS
											</h2>
											<h3
												className="my-2"
												style={{ color: "white", fontSize: "1.2rem" }}
											>
												Durée 32’58’’
											</h3>
											<p
												className="my-5"
												style={{ color: "#3e7b96", fontSize: "1.2rem" }}
											>
												Voici un album varié et coloré, laissant une grande part
												à la confession de la parole de Dieu. Cette parole qui
												soutient toute chose, retentit au travers de la
												création.
												<br />
												Le cœur ainsi éveillé peut méditer la sagesse infiniment
												variée du Créateur. Il est aussi conduit à lui rendre
												gloire en se confiant dans sa bonté manifestée en Jésus.
												Les enfants, petits et grands, s’unissent pour le
												chanter dans cet album.
											</p>
										</div>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	nouveauteCd18() {
		return (
			<>
				<div
					className="container-fluid"
					style={{ minHeight: "600px", backgroundColor: "#b8babc" }}
				>
					<div className="row">
						<div
							className="col-1 d-none d-lg-block"
							style={{ minHeight: "600px" }}
						>
							<div
								className="tourne2"
								style={{
									height: "150px",
									width: "600px",
									marginLeft: "-248px",
									backgroundColor: "#d49f29"
								}}
							>
								<br />
								<br />
								<h2
									style={{ color: "#FFFFFF", fontSize: "3em" }}
									className="text-center"
								>
									NOUVEAUTÉ
								</h2>
							</div>
						</div>
						<div className="col-lg-11 col-md-12" style={{ minHeight: "600px" }}>
							<div className="container">
								<Link
									to="/media/1412/Avec-des-Cris-de-Joie-n°18"
									className="row home-block-link nouveaute"
								>
									<div className="block col-xl-5 col-md-5 col-sm-12 mt-3 mb-3">
										<div className="pt-5 mt-2">
											{/* <img src={cd18} className="img-fluid w-100" alt="" /> */}
										</div>
									</div>
									<div className="block col-xl-7 col-md-7 col-sm-12 mt-3 mb-3">
										<div className="mt-5">
											<h2
												className="mt-2"
												style={{ color: "#355b70", fontSize: "2rem" }}
											>
												AVEC DES CRIS DE JOIE N°18
											</h2>
											<h3
												className="my-2"
												style={{ color: "white", fontSize: "1.2rem" }}
											>
												Durée 42’07’’
											</h3>
											<p
												className="my-5"
												style={{ color: "#FFFFFF", fontSize: "1.2rem" }}
											>
												Jésus est le bon berger. Il appelle les brebis, mêmes
												étrangères à sa bergerie. Sa voix peut réveiller ceux
												qui dorment dans la mort spirituelle, loin de sa
												connaissance. Les incrédules peuvent recevoir la foi en
												entendant sa parole. Les orphelins ont également sa
												promesse de ne plus être seuls.
												<br />
												Qui peut comprendre ces choses et saisir ce que fait
												Dieu ? Personne. Mais toi aussi tu peux l’entendre.
												Crois simplement en Jésus puis...tiens ferme en sa
												bonté.
											</p>
										</div>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
	nouveauteCdLive2024 = () => {
		return (
			<Nouveaute
				backgroundColorNouveaute="#fee8d0"
				// backgroundColorNouveaute="#f9b94b"
				// backgroundColorNouveaute="#f9b94b"
				backgroundColorContenu="#f9b94b"
				colorTexteNouveaute="#1fa59e"
				// colorTexteNouveaute="#2a307a"
				hauteurNouveaute="141px"
				largeurNouveaute="509px"
				margeGauche="-198px"
				margeHaut="184px"
				lienImage={`${tools.lienPhotos}/photos/LIVE2024.jpg`}
				lienHref="/media/2313/Les-regards-sur-Jésus"
				image={
					<>
						<img
							src={`${tools.lienPhotos}/LIVE2024.jpg`}
							className="img-fluid w-100"
							alt=""
							style={{
								height: "350px",
								objectFit: "contain"
								// filter: "drop-shadow(0px 0px 25px #fee8d0)"
							}}
						/>
					</>
				}
				contenu={
					<div className="mt-4">
						<h2 className="mt-2" style={{ color: "#2a307a", fontSize: "2rem" }}>
							LES REGARDS SUR JÉSUS
						</h2>
						<h3
							className="my-2"
							style={{ color: "#2a307a", fontSize: "1.2rem" }}
						>
							Sélection live 2024
							<br />
							Durée 89’
						</h3>
						<p
							className="mt-4 mb-5"
							style={{ color: "#2a307a", fontSize: "1.2rem" }}
						>
							Cette compilation réunit des enregistrements de chants de chorale
							interprétés en live durant ces dernières années. Une bonne partie
							d’entre eux provient des camps de jeunes organisés à Anduze, en
							fin d'été et à Noël. On reconnait parfois l’acoustique
							particulière du temple d’Anduze, lieu privilégié pour les soirées
							de clôture. Au milieu, quelques pistes plus classiques
							interprétées par la chorale régionale comme la cantate de Bach qui
							malgré sa longueur, vaut la peine d’être écoutée tant sa solennité
							est saisissante.
							<br />
							Cet album permet de raviver de bons souvenirs aux participants
							mais surtout de faire bénéficier au plus grand nombre ces moments
							de témoignage rafraîchissants pour la foi.
						</p>
					</div>
				}
			/>
		);
	};
	nouveauteCd21 = () => {
		return (
			<Nouveaute
				backgroundColorNouveaute="#95a8a2"
				// backgroundColorNouveaute="#f9b94b"
				// backgroundColorNouveaute="#f9b94b"
				backgroundColorContenu="#778a84"
				colorTexteNouveaute="#4f6163"
				// colorTexteNouveaute="#2a307a"
				hauteurNouveaute="141px"
				largeurNouveaute="509px"
				margeGauche="-198px"
				margeHaut="184px"
				lienImage={`${tools.lienPhotos}/photos/CD21.jpg`}
				lienHref="/media/2390/Avec-Des-Cris-de-Joie-n°21"
				image={
					<>
						<img
							src={`${tools.lienPhotos}/CD21.jpg`}
							className="img-fluid w-100"
							alt=""
							style={{
								height: "350px",
								objectFit: "contain"
								// filter: "drop-shadow(0px 0px 25px #fee8d0)"
							}}
						/>
					</>
				}
				contenu={
					<div className="mt-4">
						<h2 className="mt-2" style={{ color: "#4f6163", fontSize: "2rem" }}>
							Fondés en Christ
						</h2>
						<h3
							className="my-2"
							style={{ color: "#4f6163", fontSize: "1.2rem" }}
						>
							Avec Des Cris De Joie n°21
							<br />
							Durée 39’
						</h3>
						<p
							className="mt-4 mb-5"
							style={{ color: "#e9ead8", fontSize: "1.2rem" }}
						>
							Il est un fondement inébranlable au-delà des siècles. Celui qui
							s’y appuie n’a pas hâte de fuir, sa foi est maintenue malgré les
							tempêtes de l’âme et le sentiment de faiblesse. Les croyants ne
							sont pas laissés sans secours et peuvent espérer. C'est la réalité
							que veulent proclamer ces cantiques : Jésus-Christ fait tenir
							debout les siens, il les soutient et les affermit sans se lasser,
							jusqu'à la fin.
						</p>
					</div>
				}
			/>
		);
	};
	nouveauteCd20 = () => {
		return (
			<Nouveaute
				backgroundColorNouveaute="#f3d7c2"
				backgroundColorContenu="#8d443b"
				colorTexteNouveaute="#830803"
				lienImage={`${tools.lienPhotos}/photos/CD20.jpg`}
				lienHref="/media/2038/Avec-Des-Cris-de-Joie-n°20"
				image={
					<>
						<img
							src={`${tools.lienPhotos}/CD20.jpg`}
							className="img-fluid w-100"
							alt=""
							style={{
								height: "350px",
								objectFit: "contain",
								filter: "drop-shadow(0px 0px 25px #830803)"
							}}
						/>
					</>
				}
				contenu={
					<div className="mt-4">
						<h2 className="mt-2" style={{ color: "#f3d7c2", fontSize: "2rem" }}>
							AVEC DES CRIS DE JOIE N°20
						</h2>
						<h3
							className="my-2"
							style={{ color: "#f3d7c2", fontSize: "1.2rem" }}
						>
							Durée 38’
						</h3>
						<p
							className="mt-4 mb-5"
							style={{ color: "#f3d7c2", fontSize: "1.2rem" }}
						>
							Voici un nouvel album de chants inédits. L’expression vocale y est
							un peu moins collective, les circonstances sanitaires nous y ont
							contraints. Petits chœurs mixtes et solistes sont donc
							privilégiés.
							<br />A l’instar des psalmistes, la foi et la Parole de Dieu sont
							proclamées souvent au milieu des supplications. Le serviteur de
							Dieu invoque son maître et persévère ainsi dans la voie du salut.
							Il répond à cet appel puissant qui fonde la relation de Dieu à
							l’homme : « Invoque-moi ! » Que ces cantiques suscitent en chacun
							la réponse à cet appel de grâce.
						</p>
					</div>
				}
			/>
		);
	};

	nouveauteCd19() {
		return (
			<Nouveaute
				backgroundColorNouveaute="#f8d68c"
				backgroundColorContenu="#53867a"
				colorTexteNouveaute="#FFFFFF"
				lienImage={`${tools.lienPhotos}/nouveauteCD19.png`}
				lienHref="/media/1796/Avec-Des-Cris-de-Joie-n°19"
				image={
					<>
						<img
							src={`${tools.lienPhotos}/nouveauteCD19.png`}
							className="img-fluid w-100"
							alt=""
						/>
					</>
				}
				contenu={
					<div className="mt-4">
						<h2 className="mt-2" style={{ color: "#193733", fontSize: "2rem" }}>
							AVEC DES CRIS DE JOIE N°19
						</h2>
						<h3 className="my-2" style={{ color: "white", fontSize: "1.2rem" }}>
							Durée 39’16’’
						</h3>
						<p
							className="mt-4 mb-5"
							style={{ color: "#FFFFFF", fontSize: "1.2rem" }}
						>
							La Mission Timothée vient d’éditer son 19 ième album. Comme le
							précédent, beaucoup de cantiques sont des inédits. Dans ce
							contexte de restriction sanitaire qui a fortement entravé la
							louange dans l’Eglise et les activités de chorales, il sera
							d’autant plus appréciable de découvrir une nouvelle fois la force
							de la Parole chantée et proclamée par les fidèles.
							<br />
							En attendant de pouvoir les chanter dans la communion de
							l’assemblée, nous espérons que ces cantiques pourront résonner
							dans les foyers et dans les cœurs en vue de fortifier la foi et
							l’attachement au Seigneur.
						</p>
					</div>
				}
			/>
		);
	}

	video() {
		return (
			<div
				className="container-fluid no-gutters pt-4 pb-5"
				style={{ backgroundColor: "#d9d9d9" }}
			>
				<div className="container pt-3 pb-5">
					<div className="row">
						<div className="col-md-6">
							<div className="p-3">
								<h1 style={{ color: "#000" }} className="ft-open">
									FOCUS
								</h1>
								<p>
									Présentation et téléchargement du nouvel album
									<br />
									<Link
										to="/media/2390/Avec-Des-Cris-de-Joie-n°21"
										style={{ color: "#414042" }}
									>
										<strong>
											<i>« Avec des Cris de Joie n°21 »</i>
										</strong>
									</Link>
								</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="video-container" style={{ top: "24px" }}>
								<iframe
									width="560"
									height="315"
									src="https://www.youtube.com/embed/Gmy6PDqtbek"
									frameborder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<div className="container home">
					<div className="my-5">
						{/* <h2 className="text-center mb-5">PRÉSENTATION</h2> */}

						<h1 className="contenu-h1 text-uppercase ">PRÉSENTATION</h1>
						<p className="mb-5 pt-5">
							Les chorales et solistes de la Mission Timothée enregistrent les
							chants qu’ils composent, pour la plupart, à partir de textes
							bibliques.
							<br />
							<br />
							Les interprètes sont des membres de l’équipe missionnaire de la
							maison d’accueil d’Anduze ainsi que des assemblées du Gard et de
							l’Hérault. Réunie régulièrement pour des répétitions, la chorale
							participe occasionnellement aux cultes et réunions d’Églises. Elle
							est sollicitée parfois à l’occasion d’événements particuliers dans
							les temples des Cévennes ou encore lors de marchés.
							<br />
							<br />
							La musique ne fait appel à aucun style en particulier ou dit « à
							la mode », le but étant qu’elle soit avant tout inspirée par la
							Parole de Dieu et à son service. Les voix accompagnées des
							instruments expriment la foi chrétienne et cherchent à communiquer
							une grâce à ceux qui l’écoutent.
							<br />
							<br />
							Ce site vous propose de découvrir la production musicale des
							chorales de la Mission Timothée
							<br />
							<br />
						</p>
					</div>
				</div>

				{this.video()}

				{this.nouveauteCd21()}
				{this.nouveauteCdLive2024()}
				{this.nouveauteApplicationMobile()}
				{/* {this.nouveauteCd20()} */}
				{/* {this.nouveauteCd19()} */}
				{this.nouveauteCdEnfants()}
				<div
					className="container-fluid bg-grey-light"
					style={{ minHeight: "480px" }}
				>
					<div className="container home">
						<div className="text-center">
							<br />
							<br />
							<h1 className="contenu-h1 text-uppercase">COLLECTIONS</h1>
							<div className="pt-5">
								<ModuleEncartListMt data={ELEMENTS_ENCARTS_1} />
							</div>
						</div>
					</div>
				</div>
				<div className="container home">
					<br />
					<h2 className="text-center mt-5">NOUVEAUTÉS VIDÉOS</h2>
					<div className="mb-5 nouveauteVideos">
						<br />
						<br />
						<div className="row">
							<div className="col-md-4 col-sm-12 mt-3 mb-3">
								<div className="pt-3">
									<Link to="/media/773">
										<img
											src="https://i.ytimg.com/vi/peLfK4WNXKc/mqdefault.jpg"
											className="img-fluid w-100"
											alt=""
										/>
										<h5 className="p-3">Il est un trône de gloire</h5>
									</Link>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 mt-3 mb-3">
								<div className="pt-3">
									<Link to="/media/1091">
										<img
											src="https://i.ytimg.com/vi/gn2yal5nYu4/mqdefault.jpg"
											className="img-fluid w-100"
											alt=""
										/>
										<h5 className="p-3">
											"Dieu autrefois" par la chorale de la Mission Timothée
											région Est.
										</h5>
									</Link>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 mt-3 mb-3">
								<div className="pt-3">
									<Link to="/media/1388">
										<img
											src="https://i.ytimg.com/vi/3o1ZXzQobKU/mqdefault.jpg"
											className="img-fluid w-100"
											alt=""
										/>
										<h5 className="p-3">Que ferons nous</h5>
									</Link>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 mt-3 mb-3">
								<div className="pt-3">
									<Link to="/media/1389">
										<img
											src="https://i.ytimg.com/vi/ab8LwEqihXY/mqdefault.jpg"
											className="img-fluid w-100"
											alt=""
										/>
										<h5 className="p-3">Dieu de fidélité</h5>
									</Link>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 mt-3 mb-3">
								<div className="pt-3">
									<Link to="/media/1390">
										<img
											src="https://i.ytimg.com/vi/yrh_HuBInpA/mqdefault.jpg"
											className="img-fluid w-100"
											alt=""
										/>
										<h5 className="p-3">
											Messie de Haendel extrait "un enfant nous est né"
										</h5>
									</Link>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 mt-3 mb-3">
								<div className="pt-3">
									<Link to="/media/385">
										<img
											src="https://i.ytimg.com/vi/XMZus3YnsTo/mqdefault.jpg"
											className="img-fluid w-100"
											alt=""
										/>
										<h5 className="p-3">Ton trône est éternel</h5>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(Home);
