import React from "react";
import { Link } from "react-router-dom";
import "./Home.scss";

export class Nouveaute extends React.Component {
	componentDidMount() {}

	render() {
		return (
			<>
				<div
					className="container-fluid"
					style={{
						minHeight: "450px",
						backgroundColor: this.props.backgroundColorContenu
					}}
				>
					<div className="row">
						<div
							className="col-1 d-none d-lg-block"
							style={{ minHeight: "450px" }}
						>
							<div
								className="tourne2-19"
								style={{
									height: this.props.hauteurNouveaute || "150px",
									width: this.props.largeurNouveaute || "450px",
									marginLeft: this.props.margeGauche || "-173px",
									//marginTop: this.props.margeHaut || "auto",
									marginTop: this.props.margeHaut || "",
									backgroundColor: this.props.backgroundColorNouveaute
								}}
							>
								<br />
								<br />
								<h2
									style={{
										color: this.props.colorTexteNouveaute,
										fontSize: "3em"
									}}
									className="text-center"
								>
									NOUVEAUTÉ
								</h2>
							</div>
						</div>
						<div className="col-lg-11 col-md-12" style={{ minHeight: "450px" }}>
							<div className="container">
								<Link
									to={this.props.lienHref}
									className="row home-block-link nouveaute"
								>
									<div className="block col-xl-5 col-md-5 col-sm-12 mt-3 mb-3">
										<div className="pt-4 mt-2">
											{/* <img
                                                src={this.props.lienImage}
                                                className="img-fluid w-100"
                                                alt=""
                                            /> */}

											{this.props.image}
										</div>
									</div>
									<div className="block col-xl-7 col-md-7 col-sm-12 mt-3 mb-3">
										{this.props.contenu}
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
