import React, { useState, useEffect, useCallback } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { api } from "@mitim/react-mitim";

export default function PaiementStripeForm(props) {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState("");
	const [champsComplets, setChampsComplets] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {}, []);

	const cardStyle = {
		hidePostalCode: true,
		style: {
			base: {
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#32325d"
				}
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a"
			}
		}
	};

	const handleChange = useCallback(async event => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		console.log(event.complete);
		setChampsComplets(event.complete);
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	}, []);

	const handleSubmit = async ev => {
		ev.preventDefault();
		setProcessing(true);

		const payload = await stripe.confirmCardPayment(props.idIntentStripe, {
			payment_method: {
				card: elements.getElement(CardElement),
				billing_details: {
					name: ev.target.name.value
				}
			}
		});

		if (payload.error) {
			setError(`Payment failed ${payload.error.message}`);
			setProcessing(false);
			props.self.setState({
				messageModal: (
					<>
						Votre paiement n'a pas pu être effectué, veuillez réessayer plus
						tard ou nous contacter via la page contact
					</>
				),
				showModal: true
			});
		} else {
			console.log(payload);

			const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
			const defaultHeaders = {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Basic " + tokenPublic
			};

			let createPaymentResponse = {
				clientSecret: payload.paymentIntent.client_secret,
				statusApresPaiement: payload.paymentIntent.status,
				montantPaye: payload.paymentIntent.amount
			};
			fetch(`${api.timediaUrl}/public/achatMedia/transaction`, {
				method: "PUT",
				body: JSON.stringify(createPaymentResponse),
				headers: defaultHeaders
			})
				.then(res => {
					return res.json();
				})
				.then(data => {
					console.log(data.data);
					if (data.data.paymentSuccess) {
						setError(null);
						setProcessing(false);
						setSucceeded(true);
						props.self.setState(
							{
								messageModal: (
									<>
										Votre paiement a bien été effectué, vous recevrez un E-Mail
										de confirmation.
										<br />
										Merci
									</>
								),
								showModal: true,
								paymentSuccess: true
							},
							() => {}
						);
					} else {
						props.self.setState({
							messageModal:
								"Le paiement à achoué, veuillez réessayer plus tard ou nous contacter via la page contact",
							showModal: true
						});
					}
				})
				.catch(err => {
					console.log(err);
					props.self.setState({
						messageModal:
							"Le serveur est indisponible, veuillez réessayer plus tard",
						showModal: true
					});
				});
		}
	};

	return (
		<>
			<form id="payment-form" onSubmit={handleSubmit}>
				<CardElement
					id="card-element"
					options={cardStyle}
					onChange={handleChange}
				/>

				<button
					disabled={!champsComplets}
					id="submit"
					style={{}}
					className="mt-4"
				>
					<span id="button-text">
						{processing ? (
							<div className="spinner" id="spinner"></div>
						) : (
							"Payer"
						)}
					</span>
				</button>
			</form>
		</>
	);
}
