import React from "react";
import Page from "../Page";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import "./ElasticCantiques.scss";
import { NavLink, Link } from "react-router-dom";

import {
	ErrorBoundary,
	Facet,
	SearchProvider,
	SearchBox,
	Results,
	PagingInfo,
	ResultsPerPage,
	Paging,
	Sorting,
	WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { Button } from "@material-ui/core";

import {
	buildAutocompleteQueryConfig,
	buildFacetConfigFromConfig,
	buildSearchOptionsFromConfig,
	buildSortOptionsFromConfig,
	getConfig,
	getFacetFields
} from "./config/config-helper";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
	searchKey,
	engineName,
	hostIdentifier,
	endpointBase
});
const config = {
	searchQuery: {
		facets: buildFacetConfigFromConfig(),
		...buildSearchOptionsFromConfig()
	},
	autocompleteQuery: buildAutocompleteQueryConfig(),
	apiConnector: connector,
	alwaysSearchOnInitialLoad: true
};

const IconeEcoute = () => (
	<svg
		width="25px"
		height="25px"
		className="MuiSvgIcon-root"
		focusable="false"
		viewBox="0 0 2810 2770"
		aria-hidden="true"
	>
		<g id="layer101" fill="#2d5467" stroke="none">
			<path d="M1185 2756 c-310 -52 -563 -181 -780 -400 -200 -201 -332 -452 -381 -726 -26 -140 -23 -374 4 -506 66 -311 217 -573 445 -772 191 -166 398 -268 657 -323 149 -32 410 -32 557 -1 190 41 360 113 523 221 105 69 287 249 357 352 101 148 181 334 219 509 26 120 27 429 1 550 -117 543 -525 954 -1076 1081 -112 26 -408 35 -526 15z" />
		</g>
		<g id="layer102" fill="#fefeff" stroke="none">
			<path d="M1398 1911 c-18 -9 -112 -67 -209 -129 l-177 -112 -159 0 c-220 0 -203 23 -203 -287 0 -231 1 -241 21 -259 19 -17 40 -19 186 -22 l164 -3 196 -125 c109 -68 207 -124 219 -124 12 0 32 9 45 19 l24 19 3 489 c2 477 2 490 -17 515 -27 34 -54 40 -93 19z" />
			<path d="M1742 1887 c-41 -44 -28 -89 37 -121 161 -82 249 -232 239 -407 -4 -64 -11 -91 -41 -151 -41 -84 -86 -131 -177 -186 -75 -45 -93 -77 -69 -120 26 -47 58 -50 135 -12 94 46 190 143 238 241 83 170 85 338 4 505 -60 125 -233 274 -318 274 -15 0 -36 -10 -48 -23z" />
			<path d="M1631 1631 c-12 -12 -21 -34 -21 -51 0 -24 10 -37 49 -67 64 -49 88 -102 71 -161 -8 -33 -22 -50 -66 -83 -51 -37 -55 -43 -52 -76 2 -25 11 -42 30 -56 25 -18 31 -19 69 -8 48 15 116 79 144 136 23 49 31 146 16 201 -33 123 -181 224 -240 165z" />
		</g>
	</svg>
);
const IconeLire = () => (
	<svg
		width="25px"
		height="25px"
		className="MuiSvgIcon-root"
		focusable="false"
		viewBox="0 0 2930 2930"
		aria-hidden="true"
	>
		<g id="layer101" fill="#404142" stroke="none">
			<path d="M1296 2850 c-306 -39 -585 -169 -804 -375 -466 -438 -569 -1149 -246 -1695 215 -364 587 -615 1004 -676 159 -23 384 -15 530 19 684 161 1140 780 1080 1467 -30 343 -169 631 -420 873 -202 196 -427 314 -700 368 -110 22 -343 32 -444 19z" />
		</g>
		<g id="layer102" fill="#fdfefe" stroke="none">
			<path d="M875 2309 c-17 -4 -44 -22 -60 -39 l-30 -31 0 -719 0 -718 25 -27 c52 -56 40 -55 562 -55 l483 1 167 152 166 152 1 595 c1 580 0 596 -19 628 -12 19 -38 41 -63 52 -40 19 -71 20 -622 19 -319 0 -594 -5 -610 -10z m1135 -659 l0 -500 -45 0 c-42 0 -45 2 -35 19 16 32 12 60 -15 86 l-24 25 -419 0 c-409 0 -420 -1 -446 -21 -31 -25 -34 -66 -7 -100 l19 -24 356 -3 357 -3 -16 -22 c-11 -16 -15 -48 -15 -119 l0 -98 -380 0 -380 0 0 630 0 630 525 0 525 0 0 -500z" />
			<path d="M1043 1850 c-48 -20 -58 -87 -17 -119 26 -20 37 -21 446 -21 l419 0 24 25 c30 29 32 64 6 96 l-19 24 -419 2 c-230 1 -428 -2 -440 -7z" />
			<path d="M1044 1656 c-50 -22 -60 -78 -21 -114 23 -22 26 -22 449 -22 l427 0 20 26 c28 36 26 65 -5 95 l-26 24 -406 2 c-326 2 -413 0 -438 -11z" />
			<path d="M1044 1466 c-50 -22 -60 -78 -21 -114 23 -22 26 -22 446 -22 l422 0 24 25 c41 40 28 93 -29 115 -44 16 -803 13 -842 -4z" />
		</g>
	</svg>
);
const IconeTelecharger = () => (
	<svg
		width="25px"
		height="25px"
		className="MuiSvgIcon-root"
		focusable="false"
		viewBox="0 0 2690 2220"
		aria-hidden="true"
	>
		<g id="layer101" fill="#404142" stroke="none">
			<path d="M20 2195 c0 -14 -4 -25 -10 -25 -6 0 -10 -137 -10 -390 l0 -390 105 0 105 0 0 310 0 310 1130 0 1130 0 0 -310 0 -310 110 0 110 0 0 390 0 390 -35 0 c-31 0 -35 3 -35 25 l0 25 -1300 0 -1300 0 0 -25z" />
			<path d="M1294 1526 c-32 -14 -658 -615 -668 -642 -11 -30 -6 -100 10 -122 39 -57 131 -69 186 -25 18 14 114 106 213 202 l180 176 5 -522 5 -522 29 -30 c51 -53 120 -54 179 -2 l32 29 5 522 5 522 195 -189 c107 -105 205 -195 217 -201 30 -17 88 -15 123 3 54 28 76 106 46 163 -20 37 -629 624 -663 639 -36 16 -61 15 -99 -1z" />
		</g>
	</svg>
);
const IconeYoutube = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 48 48"
		width="34px"
		height="34px"
	>
		<path
			fill="#FF3D00"
			d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
		/>
		<path fill="#FFF" d="M20 31L20 17 32 24z" />
	</svg>
);

const Element = props => {
	const type_fichiers_presents = props.result.type_fichiers_present.raw;
	return (
		<div className="col-12 mt-1 result" style={{ backgroundColor: "#fff" }}>
			<div className="row pl-md-3">
				<div className="col-12 mt-1 result affichageCantiques">
					<Link
						//to={``}
						to={`/media/${props.result.id.raw}/${props.result.titre.raw}`}
						className="lien"
					>
						<div>
							<span
								className="text-white pl-3 pr-3 ft-lato-regular pt-2 pb-1"
								style={{
									fontSize: "0.8em",
									backgroundColor: "rgb(124, 180, 215)"
								}}
							>
								<>Cantique</>
							</span>
						</div>
						<div className="select pr-2">
							<div
								className="pt-2 text-black ft-alegreya-sans-light pb-2"
								style={{ fontSize: "1em" }}
							>
								{/* {props.data.videoYoutubes.length > 0 && (
								<img
									src={
										"https://i.ytimg.com/vi/" +
										props.data.videoYoutubes[0].videoYoutube.idYoutube +
										"/mqdefault.jpg"
									}
									className="img-fluid pr-3 d-xl-inline d-block"
									alt=""
									style={{ maxWidth: "200px" }}
								/>
							)} */}
								<span style={{ verticalAlign: "bottom" }}>
									<span className="pb-5">
										{/* {props.data.livreBibles.map(data => (
										<>
											<strong>
												{data.livreBible.nom1 + " " + data.ref}
											</strong>{" "}
											|{" "}
										</>
									))} */}
										<strong>{props.result.titre.raw}</strong>
									</span>
								</span>
								<span style={{ float: "right" }}>
									{type_fichiers_presents != null &&
										type_fichiers_presents.length > 0 && (
											<>
												{(type_fichiers_presents.includes("Vidéo Youtube") ||
													type_fichiers_presents.includes("midi")) && (
													<span className="pl-2">
														<IconeYoutube />
													</span>
												)}
												{(type_fichiers_presents.includes("audio") ||
													type_fichiers_presents.includes("midi")) && (
													<span className="pl-2">
														<IconeEcoute />
													</span>
												)}
												{(type_fichiers_presents.includes("document_pdf") ||
													type_fichiers_presents.includes("inconnu")) && (
													<span className="pl-2">
														<IconeLire />
													</span>
												)}
												<span className="pl-2">
													<IconeTelecharger />
												</span>
											</>
										)}
								</span>
							</div>
						</div>
					</Link>
					<hr className="pt-2 pl-2" />
				</div>
			</div>
		</div>
	);
};

const CustomResultView = ({ result }) => {
	return <Element result={result} />;
};

const CustomResultsView = ({ children }) => {
	return <div className="row pl-md-2 pt-2">{children}</div>;
};

export default class ElasticCantiques extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		filterClass: "d-none"
	};

	toggleFilters = () => {
		this.state.filterClass === "d-none"
			? this.setState({ filterClass: "" })
			: this.setState({ filterClass: "d-none" });
	};

	render() {
		let classes = {
			filter: "col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2",
			result: "col-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10",
			pagination: "pt-5"
		};
		return (
			<div className="">
				<SearchProvider config={config}>
					<WithSearch
						mapContextToProps={({ wasSearched }) => ({ wasSearched })}
					>
						{({ wasSearched }) => {
							return (
								<ErrorBoundary>
									<div className="row">
										<div className="col-12 d-md-none p-3 filter-list">
											<div className="m-2 mb-3">
												<Button
													variant="contained"
													color="secondary"
													fullWidth
													onClick={this.toggleFilters}
												>
													Filtres
												</Button>
											</div>
										</div>
										<div
											className={
												this.state.filterClass +
												" d-md-block col-md-4 col-lg-3 col-xl-3 col-xxl-2 filter-list"
											}
										>
											<div style={{ backgroundColor: "" }} className="p-3">
												<div className="pt-2"></div>
												{wasSearched && (
													<Sorting
														label={"Trier par"}
														sortOptions={buildSortOptionsFromConfig()}
													/>
												)}
												{getFacetFields().map(field => (
													<Facet
														key={field}
														field={field}
														label={field}
														isFilterable={true}
													/>
												))}
											</div>
										</div>
										<div
											className={classes.result}
											style={{ backgroundColor: "#fff" }}
										>
											<div className="row pl-3 pr-3">
												<div
													className="pt-5 pb-4"
													style={{ width: "600px", margin: "auto" }}
												>
													<SearchBox
														inputView={({
															getAutocomplete,
															getInputProps,
															getButtonProps
														}) => (
															<>
																<div className="sui-search-box__wrapper">
																	<input
																		{...getInputProps({
																			placeholder: "Rechercher un cantique..."
																		})}
																	/>
																	{getAutocomplete()}
																</div>
																<input
																	{...getButtonProps({
																		value: "Rechercher"
																	})}
																/>
															</>
														)}
														autocompleteSuggestions={true}
													/>
												</div>

												<div className="col-12">
													<span style={{ display: "inline" }}>
														{wasSearched && (
															<PagingInfo
																view={({ start, end, totalResults }) => (
																	<div className="paging-info">
																		<strong>
																			{start} - {end} sur {totalResults}{" "}
																			résultats
																		</strong>
																	</div>
																)}
															/>
														)}
													</span>
													<span style={{ display: "inline" }}>
														{wasSearched && <ResultsPerPage />}
													</span>
												</div>

												<Results
													view={CustomResultsView}
													resultView={CustomResultView}
												/>
												<div className="col-12">
													<div
														style={{
															position: "absolute",
															left: "50%",
															translate: "-50%"
														}}
													>
														<div className="" style={{ width: "320px" }}>
															<Paging />
														</div>
													</div>
												</div>

												<p>
													<br />
													<br />
													<br />
													<br />
													<br />
												</p>
											</div>
										</div>
									</div>
								</ErrorBoundary>
							);
						}}
					</WithSearch>
				</SearchProvider>
			</div>
		);
	}
}
