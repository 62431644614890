import React from "react";
import { withTranslation } from "react-i18next";

class SlideTemplate2 extends React.Component {
	render() {
		const data = this.props.slide;

		return (
			<>
				{/* *** BARRE JAUNE ************************ */}
				<div className="col-lg-1 no-gutters d-none d-lg-block" style={{}}>
					<div
						style={{
							height: "3px",
							width: "60%",
							marginTop: "190px"
						}}
						className="bg-jaune"
					></div>
				</div>
				{/* ********************************** */}

				{/* *** ICONE ***************** */}
				<div className="col-lg-6 titreSlide">
					<div className="pt-4" style={{}}>
						{data.icone ? (
							<img
								src={require(`../../resources/icons/${data.icone}`)}
								className="img-fluid"
								style={{ width: "100px", height: "100px" }}
								alt=""
							/>
						) : (
							<div style={{ width: "100px", height: "100px" }}></div>
						)}
					</div>
					{/* ***************************** */}
					{/* *** TITRE *** */}
					<h1
						style={{ fontSize: "400%" }}
						className="ft-parisienne text-white pt-4"
					>
						{data.h1}
					</h1>
					{/* *********************************** */}
					{/* *** TITRE 2 *** */}
					<h2
						style={{ fontSize: "150%" }}
						className="ft-roboto-mono text-white pt-2 text-uppercase"
					>
						{data.h2}
					</h2>
					{/* ********************************************* */}
					{/* *** TITRE 3 *** */}
					<h3
						style={{ fontSize: "220%" }}
						className="ft-parisienne text-jaune pt-4"
					>
						{data.h3}
					</h3>
					{/* **************************************************** */}
				</div>

				<div className="col-lg-5" style={{}}></div>
			</>
		);
	}
}
export default withTranslation()(SlideTemplate2);
