// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";
// Component
import Page from "../Page";
import CustomInput, { HelperTextWithIcon } from "../CustomInput";
import { titleCase } from "../../utils/functions";
import { auth, Storage } from "@mitim/react-mitim";

class ChangerMotDePasse extends Page {
	state = {
		redirectToReferrer: false,
		token: "",
		authenticate: false,
		errorMessage: "",
		authenticationError: false,
		nom: "",
		email: "",
		prenom: "",
		password: { value: "", errorMessage: null },
		passwordConfirm: { value: "", errorMessage: null },
		passwordLengthKo: true,
		passwordCharKo: true,
		passwordNameKo: false
	};

	componentDidMount() {
		super.componentDidMount();
		this.setState({ token: this.props.match.params.token });
		auth.authenticateByToken(this.props.match.params.token, values => {
			const valeurs = JSON.stringify(values.data);

			if (valeurs !== "null") {
				console.log(values);
				// localStorage.setItem("user", valeurs);

				Storage.setUserJson(valeurs);
				this.setState({
					nom: values.data.lastname,
					prenom: values.data.firstname,
					email: values.data.email,
					authenticate: true
				});
				// this.setState({ redirectToReferrer: true });
			} else {
				this.setState({
					errorMessage: "Le lien est incorrect ou expiré !",
					authenticationError: true
				});
			}
		});
	}

	constructor(props) {
		super(props);
		this.passwordConfirm = React.createRef();
		this.password = React.createRef();
	}

	handleChange = event => {
		let target = event.target;
		let value = target.value;
		let action = target.dataset.parse;
		if (action === "upperCase") {
			value = value.toUpperCase();
		} else if (action === "lowerCase") {
			value = value.toLowerCase();
		} else if (action === "titleCase") {
			value = titleCase(value);
		}

		this.setState({ [target.name]: { value: value } }, () => {
			if (target === this.password.current) {
				let { lengthOk, charOk, nameOk } = this.checkPasswordStrengh(
					this.password.current.value
				);
				this.setState({
					passwordCharKo: !charOk,
					passwordLengthKo: !lengthOk,
					passwordNameKo: !nameOk
				});

				if (!lengthOk || !charOk || !nameOk) {
					this.password.current.setCustomValidity("Invalid");
				} else {
					this.password.current.setCustomValidity("");
				}
			} else if (target === this.passwordConfirm.current) {
				this.checkSamePassword();
				this.passwordConfirm.current.checkValidity();
			}
		});
	};

	handleSubmit = event => {
		event.preventDefault();
		this.checkPasswordStrengh(this.password.current.value);
		this.checkSamePassword();
		if (!event.target.checkValidity()) {
			// form is invalid! so we do nothing
			return;
		}
		console.log("form valid");
		// TODO Make the inscription to the API

		auth.changePassword(
			this.props.match.params.token,
			this.password.current.value,
			values => {
				const valeurs = JSON.stringify(values.data);

				if (valeurs !== "null") {
					console.log(values);
					// localStorage.setItem("user", valeurs);
					Storage.setUserJson(valeurs);
					this.setState({ redirectToReferrer: true });
				} else {
					this.setState({
						errorMessage: "Le lien est incorrect ou expiré !",
						authenticationError: true
					});
				}
			}
		);
	};

	handleInvalid = event => {
		this.setState({
			[event.target.name]: {
				errorMessage: event.target.validationMessage,
				value: event.target.value
			}
		});
	};

	checkPasswordStrengh = password => {
		// Minimum 8 char
		let lengthOk = password.length >= 8;
		let charOk = true,
			nameKo = false;
		// Contain 1 digit, 1 letter lower case and 1 letter upper case
		charOk = charOk && /\d/.test(password);
		charOk = charOk && /[a-z]/.test(password);
		charOk = charOk && /[A-Z]/.test(password);
		// Doesn't contain nom, prenom or mail
		if (this.state.nom !== "") {
			nameKo = nameKo || password.toUpperCase().includes(this.state.nom);
		}
		if (this.state.prenom !== "") {
			nameKo = nameKo || titleCase(password).includes(this.state.prenom);
		}
		if (this.state.email !== "") {
			// On regarde uniquement la partie avant le @
			let mail = this.state.email.split("@");
			nameKo = nameKo || password.toLowerCase().includes(mail[0]);
		}
		return { lengthOk: lengthOk, charOk: charOk, nameOk: !nameKo };
	};

	checkSamePassword = () => {
		if (this.passwordConfirm.current.value !== this.password.current.value) {
			this.passwordConfirm.current.setCustomValidity(
				"inscription.password-check-mismatch"
			);
		} else {
			this.passwordConfirm.current.setCustomValidity("");
		}
	};

	render() {
		const { t } = this.props;
		let { from } = this.props.location.state || { from: { pathname: "/" } };
		if (this.state.redirectToReferrer) return <Redirect to={from} />;
		return (
			<div className="container">
				{this.state.authenticationError && (
					<h2 className="text-center pt-5 pb-5">{this.state.errorMessage}</h2>
				)}
				{this.state.authenticate && (
					<>
						<h2 className="text-center pt-5">Créer un nouveau mot de passe</h2>
						<h6 className="text-center pb-5">
							{this.state.prenom} | {this.state.nom} | {this.state.email}
						</h6>
						<div className="row pb-5">
							<div className="col-md-2"></div>
							<div className="col-md-8">
								<form
									onSubmit={this.handleSubmit}
									noValidate
									method="POST"
									action="/create"
									className="pl-2 pr-2"
								>
									<CustomInput
										required
										id="password"
										label={t("inscription.password")}
										onChange={this.handleChange}
										onInvalid={this.handleInvalid}
										state={this.state.password}
										type="password"
										reference={this.password}
										dataparse="checkStrengh"
										autoComplete="new-password"
									>
										<HelperTextWithIcon error={this.state.passwordLengthKo}>
											{t("inscription.password-check-length")}
										</HelperTextWithIcon>
										<HelperTextWithIcon error={this.state.passwordCharKo}>
											{t("inscription.password-check-chars")}
										</HelperTextWithIcon>
										<HelperTextWithIcon error={this.state.passwordNameKo}>
											{t("inscription.password-check-name")}
										</HelperTextWithIcon>
									</CustomInput>
									<CustomInput
										required
										id="passwordConfirm"
										label={t("inscription.password-confirm")}
										onChange={this.handleChange}
										onInvalid={this.handleInvalid}
										state={this.state.passwordConfirm}
										type="password"
										reference={this.passwordConfirm}
										dataparse="checkConfirmation"
										autoComplete="new-password"
									/>
									<div className="row">
										<div class="col-auto mr-auto"></div>
										<div className="col-auto mt-5">
											<Button
												variant="contained"
												color="secondary"
												type="submit"
											>
												Valider
											</Button>
										</div>
									</div>
								</form>
							</div>
							<div className="col-md-2"></div>
						</div>
					</>
				)}
			</div>
		);
	}
}

export default withTranslation()(ChangerMotDePasse);
