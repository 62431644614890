import React from "react";
import "./ActivationQrCode.scss";
import { tokenPublic } from "../Constants";
import { Storage, api } from "@mitim/react-mitim";

export class ActivationQrCode extends React.Component {
	state = {
		//showModal: false,
		//paymentSuccess: false,
		codeQrCode: "",
		motDePasse: "",
		messageRetour: "",
		retourServeur: false,
		okPourCD: false,
		nomAlbum: ""
	};

	componentDidMount() {
		this.setState({ codeQrCode: this.props.match.params.token });
	}

	handleChangeText = name => event => {
		this.setState({
			motDePasse: event.target.value
		});
	};

	inputText(label, name, type, nbrCol) {
		return (
			<div className={"text-center form-group col-md-" + nbrCol}>
				<label htmlFor="" className="col-form-label ">
					{label}
				</label>
				<input
					style={{ backgroundColor: "#f6f6f6", color: "#000" }}
					type="password"
					value={this.state.motDePasse}
					onChange={this.handleChangeText(name)}
					className="form-control text-center"
					id={"Id" + name}
					placeholder={label}
					name={name + "Name"}
					required
				/>
			</div>
		);
	}

	handleSubmitFormulaire = event => {
		event.preventDefault();

		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + tokenPublic
		};

		fetch(
			`${api.timediaUrl}/public/activationQrCodeCD/${this.state.codeQrCode}?pass=${this.state.motDePasse}`,
			{
				method: "GET",
				headers: defaultHeaders
			}
		)
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					if (data.success) {
						this.setState({
							messageRetour: (
								<>
									Le QRCode à bien été validé
									<br />
									Vous pouvez donner un CD en échange de ce QRCode
								</>
							),
							okPourCD: true,
							retourServeur: true,
							nomAlbum: data.data.nomAlbum
						});
					} else {
						this.setState({
							messageRetour: (
								<>
									Le QRCode n'est plus valide !<br />
									Vous ne devez pas donner de CD
								</>
							),
							okPourCD: false,
							retourServeur: true
						});
					}
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	render() {
		const Ok = props => (
			<svg
				width={props.width}
				version="1.1"
				x="0px"
				y="0px"
				viewBox="0 0 500 500"
				style={{ enableBackground: "new 0 0 500 500" }}
				xmlSpace="preserve"
			>
				<style
					type="text/css"
					dangerouslySetInnerHTML={{
						__html:
							"\n\t   .st0{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:10;stroke-miterlimit:10;}\n\t   .st1{fill:#5DAB5B;}\n   "
					}}
				/>
				<g id="border">
					<path
						className="st0"
						d="M455.3,61.5C395.6,69.1,300.9,153.7,222,276c-6.1,9.4-11.9,18.8-17.4,28.1c0,0,0,0-0.1-0.1
		   c-0.1,0.1-0.1,0.2-0.2,0.3c-12.8-12.8-26.6-25.6-41.2-38.1c-6.8-5.8-13.6-11.4-20.3-16.9L40.2,314.2c46.9,22,87.8,48.2,119.6,75.3
		   c0-0.1,0.1-0.2,0.1-0.3c19.1,16.3,35,33,46.8,49.3c5.2-7.7,10.5-15.4,16-23.2c12-17.1,24.2-33.5,36.4-49.1c0,0,0.1,0.1,0.1,0.1
		   c81.4-104.5,162.3-173.5,200.6-170.6L455.3,61.5z"
					/>
				</g>
				<g id="object">
					<path
						className="st1"
						d="M455.3,61.5C395.6,69.1,300.9,153.7,222,276c-6.1,9.4-11.9,18.8-17.4,28.1c0,0,0,0-0.1-0.1
		   c-0.1,0.1-0.1,0.2-0.2,0.3c-12.8-12.8-26.6-25.6-41.2-38.1c-6.8-5.8-13.6-11.4-20.3-16.9L40.2,314.2c46.9,22,87.8,48.2,119.6,75.3
		   c0-0.1,0.1-0.2,0.1-0.3c19.1,16.3,35,33,46.8,49.3c5.2-7.7,10.5-15.4,16-23.2c12-17.1,24.2-33.5,36.4-49.1c0,0,0.1,0.1,0.1,0.1
		   c81.4-104.5,162.3-173.5,200.6-170.6L455.3,61.5z"
					/>
				</g>
			</svg>
		);

		const Ko = props => (
			<svg
				width={props.width}
				height={props.width}
				version="1.1"
				id="Capa_1"
				x="0px"
				y="0px"
				viewBox="0 0 412.123 412.122"
				style={{ enableBackground: "new 0 0 412.123 412.122" }}
				xmlSpace="preserve"
			>
				<g style={{ stroke: "#d40000", fill: "#F00" }}>
					<path
						d="M206.061,0C92.441,0,0,92.436,0,206.062C0,319.69,92.441,412.122,206.061,412.122
		   c113.616,0,206.062-92.432,206.062-206.061C412.123,92.436,319.677,0,206.061,0z M54.474,206.062
		   c0-83.587,68-151.588,151.587-151.588c32.816,0,63.21,10.513,88.047,28.307L82.78,294.108
		   C64.992,269.271,54.474,238.878,54.474,206.062z M206.061,357.649c-31.129,0-60.083-9.446-84.187-25.594l210.181-210.182
		   c16.147,24.096,25.593,53.064,25.593,84.188C357.648,289.651,289.651,357.649,206.061,357.649z"
					/>
				</g>
			</svg>
		);

		return (
			<>
				<div className="container pt-5 pb-5 pl-md-5 pr-md-5 mb-5 activationQrCode">
					<h3 className="text-center pt-5 pb-5">
						Retrait du CD
						<br />
						{this.state.nomAlbum}
						{/* Avec Des Cris De Joie n°20 */}
					</h3>

					{!this.state.retourServeur && (
						<form
							onSubmit={this.handleSubmitFormulaire}
							method="POST"
							className="pl-md-5 pr-md-5"
						>
							<div className="form-row text-center">
								{this.inputText(
									"Mot de passe libraire",
									"motDePasse",
									"text",
									"12"
								)}
							</div>
							<div className="text-center pt-5">
								<button
									type="submit"
									className="btn btn-primary center-block pl-5 pr-5"
								>
									Activer
								</button>
							</div>
						</form>
					)}
					{this.state.retourServeur && (
						<div className="pt-3">
							<div className="text-center">
								{!this.state.okPourCD && (
									<div className="img-fluid text-center">
										<Ko width={200} />
									</div>
								)}
								{this.state.okPourCD && (
									<div className="img-fluid">
										<Ok width={200} />
									</div>
								)}
								<h3 className="pt-4">{this.state.messageRetour}</h3>
							</div>
						</div>
					)}
				</div>
			</>
		);
	}
}
