export const DESCRIPTIONS = [
	{
		url:
			"https://www.dropbox.com/s/9m7xwyjrcofe19v/Matthieu%20-%20logo1.png?raw=1",
		texte:
			"La Webradio vous permet d'écouter les cantiques de la même façon qu'une radio."
	},
	{
		url:
			"https://www.dropbox.com/s/pr152s2hfmhd1eo/Matthieu%20-%20logo2.png?raw=1",
		texte:
			"La Playlist Studio est une playlist aléatoire composée des cantiques présents dans les albums."
	},
	{
		url:
			"https://www.dropbox.com/s/v58d86jypbfrpu4/Matthieu%20-%20logo3.png?raw=1",
		texte:
			'La Playlist Traditionnel est composée des cantiques contenus dans les CD "Nos pères chantaient".'
	},
	{
		url:
			"https://www.dropbox.com/s/glx7ijkj9mz08my/Matthieu%20-%20logo4.png?raw=1",
		texte:
			"Les cantiques de la Playlist Live ont été chantés dans les différentes églises."
	},
	{
		url:
			"https://www.dropbox.com/s/3isi3z3ihvpwsz0/Matthieu%20-%20logo5.png?raw=1",
		texte: "La Playlist Enfants contient des chants pour enfants."
	}
];
