import React, { Component } from "react";
import "./ApplicationMobile.scss";
import { DESCRIPTIONS } from "./Descriptions";
import { AVIS } from "./Avis";
import { IMAGES } from "./Images";
import Page from "../Page";
import LogoCDJ from "../../resources/logoCDJ.js";
import shadows from "@material-ui/core/styles/shadows";
import { Link } from "react-router-dom";

export class ApplicationMobile extends Page {
	constructor(props) {
		super(props);
	}

	sectionPresentation() {
		return (
			<>
				<div className="col-md-10 col-sm-12 py-md-4">
					<div className="row">
						<div className="col-md-6 col-sm-12 py-lg-5 py-md-4">
							<h1 className="ft-lato-light text-black title mt-3">
								L' application Mobile{" "}
								<span className="title-yellow">Avec des Cris de Joie</span>
							</h1>
							<p className="mt-3">
								L'application « Avec des Cris de Joie » est gratuite et vous
								permet d'obtenir tous les albums de la Mission Timothée jusqu'au
								numéro 19. L'album
								<Link to="/media/2038/Avec-Des-Cris-de-Joie-n%C2%B020">
									{" "}
									« Aves Des Cris De Joie n° 20 »
								</Link>{" "}
								et l'album live
								<Link to="/media/2313"> « Les regards sur Jésus »</Link> sont
								disponibles dans l'application après l'achat en ligne et en se
								connectant avec les identifiants du site de la Mission Timothée.
							</p>
							<div className="text-center py-3">
								<div
									style={{
										backgroundColor: "white",
										width: "100px",
										margin: "auto",
										borderRadius: "22px",
										boxShadow: "0px 2px 4px #aaa"
									}}
								>
									<LogoCDJ width={100} />
								</div>
							</div>
							<div className="text-center">
								<h4 className="titreEncart">
									Disponible dès maintenant sur Iphone, Ipad et Android
								</h4>
								<p className="note text-center mb-3">GRATUITE</p>
								<a
									href="https://play.google.com/store/apps/details?gl=FR&hl=fr&id=fr.timothee.avecdescrisdejoie"
									target="_blank"
								>
									<img
										src={IMAGES.playstore.url}
										alt=""
										className="img-fluid img-store my-2"
									/>
								</a>
								<a
									href="https://apps.apple.com/fr/app/avec-des-cris-de-joie/id1554883049"
									target="_blank"
								>
									<img
										src={IMAGES.appstore.url}
										alt=""
										className="img-fluid img-store my-2"
									/>
								</a>
							</div>
						</div>
						<div className="col-md-6 col-sm-12 text-center py-5 px-4">
							<img src={IMAGES.iphone.url} className="img-fluid img-shadow" />
						</div>
					</div>
				</div>
			</>
		);
	}

	sectionDescription() {
		return (
			<>
				<div className="row justify-content-center mw-100">
					<div className="col-lg-8 col-md-8 col-sm-10 py-4">
						<h2 className="text-center my-4">Description</h2>
						<div>
							<table className="table table-borderless">
								<tbody>
									{DESCRIPTIONS.map(description => (
										<tr>
											<th>
												<img
													src={description.url}
													alt=""
													className="logo-description shadow"
												/>
											</th>
											<td>{description.texte}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</>
		);
	}

	sectionFonctionnalites() {
		return (
			<>
				<div className="col-md-10 py-5 my-lg-4">
					<div className="row align-items-center">
						<div className="col-12 col-md-6 text-center py-4 order-2 order-md-1">
							<img
								src={IMAGES.iphone2.url}
								className="img-fluid img-shadow"
								alt=""
							/>
						</div>
						<div className="col-12 col-md-6 text-center py-4 order-1 order-md-2">
							<h2 className="ft-lato-regular pt-4">
								Ecoutez en mode hors connexion.
							</h2>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-12 col-md-6 text-center py-4  order-1 order-md-1">
							<h2 className="ft-lato-regular pt-4">
								Contrôlez l'application avec le téléphone verrouillé.
							</h2>
						</div>
						<div className="col-12 col-md-6 text-center py-4 order-2 order-md-2">
							<img src={IMAGES.iphone3.url} className="img-fluid img-shadow" />
						</div>
					</div>
				</div>
			</>
		);
	}

	sectionFonctionnalitesDetails() {
		return (
			<>
				<div className="col-sm-12">
					<h2 className="text-center">
						Affichage du recueil "Avec des Cris de Joie"
					</h2>
					{/* <p className="note text-center">(Prochainement sur Android)</p> */}
				</div>
				<div className="col-lg-3 text-center">
					<p className="text-center my-4">Affichage des cantiques</p>
					<img
						src={IMAGES.ipad_text.url}
						alt=""
						className="img-fluid w-100 img-mw-400 img-shadow"
					/>
				</div>
				<div className="col-lg-3 text-center">
					<p className="text-center my-4">Affichage des partitions</p>
					<img
						src={IMAGES.ipad_partition.url}
						alt=""
						className="img-fluid img-mw-400 w-100 img-mw-400 img-shadow"
					/>
				</div>
				<div className="col-lg-3 text-center">
					<p className="text-center my-4">
						Affichage des cantiques (version web)
					</p>
					<img
						src={IMAGES.ipad_web.url}
						alt=""
						className="img-fluid w-100 img-mw-400 img-shadow"
					/>
				</div>
			</>
		);
	}

	sectionAvis() {
		return (
			<>
				<div className="col-12 py-4 mw-100">
					<div className="text-center">
						<h2 className="pt-4">Les avis des utilisateurs</h2>
					</div>
					<div className="row flex-lg-row flex-lg-nowrap avis-list px-5 pt-3 pb-4">
						{AVIS.map(avis => (
							<div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-3 offset-lg-0 pb-3">
								<div className="card border-0">
									<div className="card-body">
										<div className="mb-2">
											<img className="star" src={IMAGES.star.url} alt="" />
											<img className="star" src={IMAGES.star.url} alt="" />
											<img className="star" src={IMAGES.star.url} alt="" />
											<img className="star" src={IMAGES.star.url} alt="" />
											<img className="star" src={IMAGES.star.url} alt="" />
										</div>
										<p className="note">
											{avis.nom}, {avis.date}
										</p>
										<h5 className="card-title">{avis.titre}</h5>
										<p className="card-text">{avis.texte}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</>
		);
	}
	sectionFooter() {
		return (
			<>
				<div className="col-12 text-center py-5 mb-5">
					<div
						style={{
							backgroundColor: "white",
							width: "150px",
							margin: "auto",
							borderRadius: "32px",
							boxShadow: "0px 2px 6px #aaa"
						}}
					>
						<LogoCDJ width={150} />
					</div>
					<h2 className="ft-lato-light text-black my-3">
						Disponible dès maintenant sur Iphone, Ipad et Android
					</h2>
					<a
						href="https://play.google.com/store/apps/details?gl=FR&hl=fr&id=fr.timothee.avecdescrisdejoie"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src={IMAGES.playstore.url}
							className="img-fluid img-store my-2"
							alt=""
						/>
					</a>
					<a
						href="https://apps.apple.com/fr/app/avec-des-cris-de-joie/id1554883049"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src={IMAGES.appstore.url}
							className="img-fluid img-store my-2"
							alt=""
						/>
					</a>
				</div>
			</>
		);
	}
	render() {
		return (
			<div className="applicationMobile container-fluid">
				<div className="section-first row justify-content-center">
					{this.sectionPresentation()}
				</div>
				<div className="section-odd row justify-content-center">
					{this.sectionDescription()}
				</div>
				<div className="section-even row justify-content-center">
					{this.sectionFonctionnalites()}
				</div>
				<div className="section-odd row justify-content-center py-5">
					{this.sectionFonctionnalitesDetails()}
				</div>
				<div className="section-even section-avis row justify-content-center">
					{this.sectionAvis()}
				</div>
				<div className="section-odd row justify-content-center">
					{this.sectionFooter()}
				</div>
			</div>
		);
	}
}
