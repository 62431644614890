// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
// Component
import Page from "../Page";
import CustomInput from "../CustomInput";
import { titleCase } from "../../utils/functions";
import { auth } from "@mitim/react-mitim";

class CompteOublie extends Page {
	state = {
		email: { value: "", errorMessage: null },
		alertClass: "",
		alertMessage: "",
		formSubmited: false,
		disableButton: false
	};

	constructor(props) {
		super(props);
	}

	handleChange = event => {
		let target = event.target;
		let value = target.value;
		let action = target.dataset.parse;
		if (action === "upperCase") {
			value = value.toUpperCase();
		} else if (action === "lowerCase") {
			value = value.toLowerCase();
		} else if (action === "titleCase") {
			value = titleCase(value);
		}
		// this.setState({ [event.target.name]: event.target.value });
		this.setState({
			[event.target.name]: {
				value: value
			}
		});
	};

	handleSubmit = event => {
		event.preventDefault();
		if (!event.target.checkValidity()) {
			// form is invalid! so we do nothing
			return;
		}
		console.log("form valid");
		// TODO Make the inscription to the API
		auth.sendAuthLinkByMail(this.state.email.value, values => {
			if (values.success) {
				this.setState({
					formSubmited: true,
					disableButton: true,
					alertClass: "alert-success",
					alertMessage: "Le mail de connexion à bien été envoyé"
				});
			} else {
				this.setState({
					formSubmited: true,
					alertClass: "alert-danger",
					alertMessage:
						"Cette adresse mail n'a pas de compte sur ce site, veuillez creer un nouveau compte ou changer l'adresse mail"
				});
			}
			console.log(values);
		});
	};

	handleInvalid = event => {
		this.setState({
			[event.target.name]: {
				errorMessage: event.target.validationMessage,
				value: event.target.value
			}
		});
	};

	render() {
		const { t } = this.props;

		return (
			<div className="container">
				<h2 className="text-center pt-5 pb-5">Envoyer un mail de connexion</h2>
				<div className="row pb-5">
					<div className="col-md-2"></div>
					<div className="col-md-8">
						{this.state.formSubmited && (
							<div
								class={"pt-3 pb-3 alert " + this.state.alertClass}
								role="alert"
							>
								{this.state.alertMessage}
							</div>
						)}
						<form
							onSubmit={this.handleSubmit}
							noValidate
							method="POST"
							action="/create"
							className="pl-2 pr-2"
						>
							<CustomInput
								required
								id="email"
								label={t("inscription.email")}
								type="email"
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.email}
								dataparse="lowerCase"
								autoComplete="username email"
							/>
							<div className="row">
								<div class="col-auto mr-auto"></div>
								<div className="col-auto mt-5">
									<Button
										variant="contained"
										color="secondary"
										type="submit"
										disabled={this.state.disableButton ? true : false}
									>
										Envoyer
									</Button>
								</div>
							</div>
						</form>
					</div>
					<div className="col-md-2"></div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(CompteOublie);
