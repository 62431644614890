// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
// Components
import Page from "../Page";
import MediaCantique from "./MediaCantique";
import MediaAlbumCd from "./MediaAlbumCd";
import MediaRecueil from "./MediaRecueil";
import { Storage, api } from "@mitim/react-mitim";

export const getMedia = (mediaId, self, callback) => {
	// const user = JSON.parse(localStorage.getItem("user"));
	const user = Storage.getUserParsed();
	let token = "";
	if (user.token === undefined) {
		token = api.tokenPublic;
		console.log("ok");
	} else {
		token = user.token;
	}

	fetch(`${api.timediaUrl}/public/mediaFull/${mediaId}`, {
		//fetch(`http://127.0.0.1:8080/timedia/public/mediaFull/${mediaId}`, {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + token
		},
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			self.setState(
				{
					media: json.data.media,
					auteurs: json.data.auteurs,
					fichiers: json.data.fichiers,
					livreBibles: json.data.livreBibles,
					mediaMedias: json.data.mediaMedias,
					videoYoutubes: json.data.videoYoutubes,
					fetchReceive: true
				},
				callback
			);
		});
	});
};

export const isMediaPurchased = (mediaId, self, callback) => {
	// const user = JSON.parse(localStorage.getItem("user"));
	const user = Storage.getUserParsed();
	let token = "";
	if (user === null) {
		token = api.tokenPublic;
	} else {
		token = user.token;
	}

	fetch(`${api.timediaUrl}/public/isMediaPurchased/${mediaId}`, {
		//fetch(`http://127.0.0.1:8080/timedia/public/isMediaPurchased/${mediaId}`, {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + token
		},
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			self.setState(
				{
					mediaPurchased: json.data
				},
				callback
			);
		});
	});
};

class MediaFactory extends Page {
	// state = {
	// 	mediaType: [],
	// 	idMediaType: ''
	// };

	// componentDidMount() {
	// 	super.componentDidMount();
	// 	getMediaType(this.props.match.params.id, this, () => {
	// 		this.setState({ idMediaType: this.state.mediaType.id });
	// 	});
	// }

	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			// console.log(prevProps.match.params.id);
			// console.log(this.props.match.params.id);
			// getMediaType(this.props.match.params.id, this, () => {
			// 	this.setState({ idMediaType: this.state.mediaType.id });
			// });
			this.loadMedia();
		}
	}

	state = {
		data: {},
		media: {},
		auteurs: [],
		fichiers: [],
		livreBibles: [],
		mediaMedias: [],
		videoYoutubes: [],
		cantique: {},
		cantiqueListen: "",
		id: "0",
		rangMediaMedia: "0",
		classementMediaMedia: "0",
		fetchReceive: false,
		mediaPurchased: false
	};

	componentDidMount() {
		super.componentDidMount();

		// getMedia(this.props.match.params.id, this, () => {
		this.loadMedia();
	}

	loadMedia() {
		getMedia(this.props.match.params.id, this, () => {
			if (this.state.media.mediaType.id === 2) {
				// Cantique
				this.setState({
					cantique: {
						cds: this.state.mediaMedias.filter(
							m => m.media && m.media.mediaType.id === 8
						),
						recueils: this.state.mediaMedias.filter(
							m =>
								m.media &&
								(m.media.mediaType.id === 10 || m.media.mediaType.id === 11)
						) // TODO change to real ID
					}
				});
				let audioFichier = this.state.fichiers.filter(
					fichier =>
						fichier.fichier &&
						fichier.fichier.fichierContentType.fichierType.id === 4
				);
				if (audioFichier.length >= 1) {
					this.setState({
						// cantiqueListen:
						// 	"https://storage.timothee.fr/ftp/data/audio/" +
						// 	audioFichier[0].fichier.sha1Sum +
						// 	"." +
						// 	audioFichier[0].fichier.extension,
						cantiqueListen: audioFichier[0].fichier.sharedLink,
						id: audioFichier[0].fichier.id,
						rangMediaMedia: audioFichier[0].rang
					});
				}
			}

			if (this.state.media.mediaPayant) {
				isMediaPurchased(this.props.match.params.id, this, () => {});
			}
		});
	}

	render() {
		const { t } = this.props;
		return (
			<>
				{this.state.fetchReceive && (
					<>
						{this.state.media.mediaType.id === 2 && (
							<MediaCantique idMedia={this.props.match.params.id} app={this} />
						)}
						{this.state.media.mediaType.id === 8 && (
							<MediaAlbumCd idMedia={this.props.match.params.id} app={this} />
						)}
						{this.state.media.mediaType.id === 10 && (
							<MediaRecueil idMedia={this.props.match.params.id} app={this} />
						)}
						{this.state.media.mediaType.id === 11 && (
							<MediaRecueil idMedia={this.props.match.params.id} app={this} />
						)}
					</>
				)}
			</>
		);
	}
}

export default withTranslation()(MediaFactory);
