import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { api } from "@mitim/react-mitim";

class Video extends Page {
	state = {
		videoYoutubes: []
	};

	componentDidMount() {
		super.componentDidMount();
		api.getVideos(this, () => {});
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<div className="container">
					{/* <h2 className="text-center">LISTE DES VIDÉOS</h2> */}

					<h1 className="contenu-h1 pt-5 pb-5 text-uppercase">
						LISTE DES VIDÉOS
					</h1>
					<div className="mb-5">
						<div className="row">
							{this.state.videoYoutubes.map(video => (
								<div
									className="col-md-4 col-sm-12 mt-3 mb-3"
									key={video.videoYoutube.id}
								>
									<div className="pt-3">
										<Link
											to={
												"/media/" +
												video.media.id +
												"/" +
												video.media.titre.split(" ").join("-")
											}
										>
											<img
												src={
													"https://i.ytimg.com/vi/" +
													video.videoYoutube.idYoutube +
													"/mqdefault.jpg"
												}
												className="img-fluid w-100"
												alt=""
											/>
											<h5 className="p-3">
												{video.videoYoutube.titre
													.split("par la chorale de la Mission Timothée")
													.join("")}
											</h5>
										</Link>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(Video);
