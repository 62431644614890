// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
// Component
import Page from "../Page";
// import auth from "./Auth";
import { auth, Storage } from "@mitim/react-mitim";

class ConnectLink extends Page {
	state = {
		redirectToReferrer: false,
		token: "",
		authenticate: false,
		errorMessage: "",
		authenticationError: false
	};

	componentDidMount() {
		super.componentDidMount();
		this.setState({ token: this.props.match.params.token });
		auth.authenticateByToken(this.props.match.params.token, values => {
			const valeurs = JSON.stringify(values.data);

			if (valeurs !== "null") {
				console.log(values);
				// localStorage.setItem("user", valeurs);
				Storage.setUserJson(valeurs);
				this.setState({
					nom: values.data.lastname,
					prenom: values.data.firstname,
					email: values.data.email,
					authenticate: true,
					redirectToReferrer: true
				});
			} else {
				this.setState({
					errorMessage: "Le lien est incorrect ou expiré !",
					authenticationError: true
				});
			}
		});
	}

	render() {
		let { from } = this.props.location.state || { from: { pathname: "/" } };
		if (this.state.redirectToReferrer) return <Redirect to={from} />;
		return (
			<div className="container">
				{this.state.authenticationError && (
					<h2 className="text-center pt-5 pb-5">{this.state.errorMessage}</h2>
				)}
				{this.state.authenticate && (
					<>
						<h2 className="text-center pt-5">Activation du compte</h2>
						<h6 className="text-center pb-5">
							{this.state.prenom} | {this.state.nom} | {this.state.email}
						</h6>
					</>
				)}
			</div>
		);
	}
}

export default withTranslation()(ConnectLink);
