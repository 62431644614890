export const IMAGES = {
	iphone: {
		url:
			"https://www.dropbox.com/s/pznz283n2higibr/Matthieu%20-%20iphone_1.png?raw=1"
	},
	iphone2: {
		url:
			"https://www.dropbox.com/s/7feztkjif1a0tva/Matthieu%20-%20iphone_2.png?raw=1"
	},
	iphone3: {
		url:
			"https://www.dropbox.com/s/xvgnu6c3n1j0od1/Matthieu%20-%20iphone_3.png?raw=1"
	},
	acdj: {
		url:
			"https://www.dropbox.com/s/r2e34913s5sltc8/Matthieu%20-%20icon.png?raw=1"
	},
	appstore: {
		url:
			"https://www.dropbox.com/s/zrixmfykmjwk528/Matthieu%20-%20app-iphone.png?raw=1"
	},
	playstore: {
		url:
			"https://www.dropbox.com/s/cyg7gz670f0ftcd/Matthieu%20-%20app-android.png?raw=1"
	},
	ipad_web: {
		url:
			"https://www.dropbox.com/s/epcn435ugoijirp/Matthieu%20-%20ipad_web.png?raw=1"
	},
	ipad_text: {
		url:
			"https://www.dropbox.com/s/j3eh3eezs49sr6f/Matthieu%20-%20ipad_text.png?raw=1"
	},
	ipad_partition: {
		url:
			"https://www.dropbox.com/s/sni13oqfwmm3vst/Matthieu%20-%20ipad_partition.png?raw=1"
	},
	star: {
		url:
			"https://www.dropbox.com/s/zvrm4osf6kzuqd6/Matthieu%20-%20star.svg?raw=1"
	}
};
