// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Components
import Header from "../Header/Header";
import Page from "../Page";
// Images
// CSS
import "../../styles/Media.scss";
import "./MediaCantique.scss";
import { tools } from "@mitim/react-mitim";

class MediaCantique extends Page {
	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
	}

	classOfCantique(fichier) {
		var classValue = "";
		if (fichier.fichier) {
			if (fichier.fichier.id === this.props.app.state.id) {
				classValue = "ml-5 my-3 ligne lineSelected";
			} else {
				classValue = "ml-5 my-3 ligne";
			}
		} else {
			classValue = "ml-5 my-3";
		}
		return classValue;
	}

	handleClick = fichier => {
		if (fichier.fichier) {
			console.log("this is:", fichier.fichier.sharedLink);
			console.log("this is:", fichier.fichier.id);
			this.props.app.setState(
				{
					cantiqueListen: fichier.fichier.sharedLink,
					id: fichier.fichier.id,
					rangMediaMedia: fichier.rang
				},
				() => {
					this.audioPlayer.current.play();
					this.audioPlayer.current.type = "audio/mp3";
				}
			);
		}
	};

	handleEnded = () => {
		for (const [index, value] of this.props.app.state.fichiers
			.filter(
				fichier =>
					fichier.fichier &&
					fichier.fichier.fichierContentType.fichierType.id === 4
			)
			.entries()) {
			if (this.props.app.state.rangMediaMedia < value.rang) {
				this.props.app.setState(
					{
						cantiqueListen: value.fichier.sharedLink,
						id: value.fichier.id,
						rangMediaMedia: value.rang
					},
					() => {
						this.audioPlayer.current.play();
						this.audioPlayer.current.type = "audio/mp3";
					}
				);
				break;
			}
		}
	};

	audio() {
		return (
			<>
				<audio
					className=""
					style={{}}
					src={this.props.app.state.cantiqueListen}
					preload="true"
					ref={this.audioPlayer}
					id="audio1"
					controls="controls"
					controlsList="nodownload"
					onEnded={this.handleEnded}
				>
					Your browser does not support HTML5 Audio!
				</audio>
			</>
		);
	}

	refsBibliques() {
		return (
			<>
				{this.props.app.state.livreBibles.length > 0 && (
					<>
						<h5>Références bibliques</h5>
						<ul>
							{this.props.app.state.livreBibles.map(referenceBiblique => (
								<li key={referenceBiblique.id}>
									{referenceBiblique.livreBible.nomSimple3 +
										referenceBiblique.ref +
										" "}
								</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}
	arrangements() {
		return (
			<>
				{this.props.app.state.media.arrangements.length > 0 && (
					<>
						<h5>Arrangements</h5>
						<ul>
							{this.props.app.state.media.arrangements.map(arrangement => (
								<li key={arrangement.id}>{arrangement.nom}</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}
	typeCantiques() {
		return (
			<>
				{this.props.app.state.media.typeCantiques.length > 0 && (
					<>
						<h5>Harmonisations</h5>
						<ul>
							{this.props.app.state.media.typeCantiques.map(arrangement => (
								<li key={arrangement.id}>{arrangement.nom}</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}
	auteurs() {
		return (
			<>
				{this.props.app.state.auteurs.length > 0 && (
					<>
						<h5>Auteurs / Compositeurs</h5>
						<ul>
							{this.props.app.state.auteurs.map(auteur => (
								<li key={auteur.auteur.id}>
									{auteur.auteur.prenom} {auteur.auteur.nom}
									{auteur.auteur.pays && <> - {auteur.auteur.pays}</>}
									{auteur.auteur.annees && <> - {auteur.auteur.annees}</>}
									{auteur.auteur.origine && <> - {auteur.auteur.origine}</>}
								</li>
							))}
						</ul>
					</>
				)}
			</>
		);
	}

	detailsCantique() {
		return (
			<div className="col-12 wrap-word">
				<h4 className="title white mb-3">Détails</h4>

				<div
					style={{
						// backgroundColor: '#f1f3f4',
						minWidth: "300px"
					}}
					className={"p-4 bg-white"}
				>
					{this.props.app.state.media.origine && (
						<>
							<h5>Origine</h5>
							<ul>
								<li>{this.props.app.state.media.origine.nom}</li>
							</ul>
						</>
					)}
					{this.refsBibliques()}
					{this.arrangements()}
					{this.typeCantiques()}
					{this.auteurs()}
				</div>
			</div>
		);
	}

	isContientFichiersAudios() {
		let contientAudio = false;
		this.props.app.state.fichiers.map(fichier => {
			if (fichier.fichier.fichierContentType.fichierType.id === 4) {
				contientAudio = true;
			}
		});
		return contientAudio;
	}

	render() {
		const titre = this.props.app.state.media.titre;
		const sousTitre = this.props.app.state.media.sousTitre;
		const descriptionMeta = this.props.app.state.metaTagKeyword;
		return (
			<>
				<Header
					slide={{
						template: 2,
						image: "cdj-home.jpg",
						h1: titre,
						h2: sousTitre,
						descriptionMeta: descriptionMeta
					}}
				/>

				<div className="Media">
					{/* <h3 className="container text-center title">
						{this.props.app.state.media.titre}
					</h3> */}

					{tools.titreSousTitre(titre, sousTitre)}

					<div className="container-fluid bg-green-to-blue pb-5">
						<div className="container mt-3">
							<div className="row">
								{this.props.app.state.videoYoutubes.length > 0 && (
									<div className="col-12 pt-5">
										{this.props.app.state.videoYoutubes.map(video => (
											<div key={video.videoYoutube.id} className="pt-3 pb-3">
												<h4 className="mb-3 white text-uppercase text-center pb-2">
													VIDÉO - {video.videoYoutube.titre}
												</h4>

												<div className="text-center">
													<div class="embed-responsive embed-responsive-16by9">
														<iframe
															class="embed-responsive-item"
															src={
																"https://www.youtube.com/embed/" +
																video.videoYoutube.idYoutube +
																"?rel=0"
															}
															frameBorder={0}
															allowFullScreen
															modestbranding={1}
															controls={0}
															rel={0}
															info={0}
															allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
														/>
													</div>
												</div>
											</div>
										))}
									</div>
								)}
							</div>
							<div className="row">
								{this.props.app.state.media.contenu && (
									<div className="col-12 col-md-7 mt-3 mt-md-5">
										<h4 className="title white mb-3">Paroles cantique</h4>
										<div
											className="paroles bg-white p-3 p-md-5"
											dangerouslySetInnerHTML={{
												__html: this.props.app.state.media.contenu
											}}
										/>
									</div>
								)}
								<div className="col-12 col-md-5 mt-3 mt-md-5 row">
									{this.isContientFichiersAudios() && (
										<div className="col-12 wrap-word">
											<h4 className="title white mb-3">
												Fichiers audio disponibles
											</h4>

											<div
												style={{
													backgroundColor: "#f1f3f4",
													minWidth: "300px"
												}}
											>
												<div className="text-center mb-3 pt-4">
													{this.audio()}
												</div>

												<table
													className="p-3 p-md-3 table table-condensed player"
													style={{ backgroundColor: "#f1f3f4" }}
												>
													<tbody>
														{this.props.app.state.fichiers.map(
															fichier =>
																fichier.fichier.fichierContentType.fichierType
																	.id === 4 && (
																	<tr
																		key={fichier.fichier.id}
																		className={this.classOfCantique(fichier)}
																		onClick={this.handleClick.bind(
																			this,
																			fichier
																		)}
																	>
																		<td className="player rang">
																			<span
																				style={{ color: "#666" }}
																				className="pl-4"
																			>
																				{" "}
																			</span>
																		</td>
																		<td>{fichier.fichier.nom}</td>
																	</tr>
																)
														)}
													</tbody>
												</table>
											</div>
										</div>
									)}

									{this.detailsCantique()}

									{this.props.app.state.fichiers.length > 0 && (
										<div className="col-12 pt-3 wrap-word fichiersATelecharger">
											<h4 className="title white mb-3">
												Fichier{this.props.app.state.fichiers.length > 1 && "s"}{" "}
												à télécharger
											</h4>
											<ul
												className="bg-blue p-1 p-md-4"
												style={{ minWidth: "300px" }}
											>
												{this.props.app.state.fichiers.map(fichier => (
													<li key={fichier.fichier.id} className="my-1">
														<a
															href={
																fichier.fichier.sharedLink &&
																fichier.fichier.sharedLink
															}
														>
															{fichier.fichier.nom +
																"." +
																fichier.fichier.extension}
															{fichier.fichier.description &&
																" - " + fichier.fichier.description}
														</a>
													</li>
												))}
											</ul>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div style={{ backgroundColor: "#2d5467", height: "40px" }} />
					<div
						className="container-fluid bg-blue"
						style={{ minHeight: "30px" }}
					>
						<div className="container">
							<div className="row pb-5">
								{this.props.app.state.cantique.cds &&
									this.props.app.state.cantique.cds.length > 0 && (
										<div className="col-12 col-md-6 pt-5 text-center">
											<h4 className="text-uppercase">
												CD{this.props.app.state.cantique.cds.length > 1 && "s"}{" "}
												dans le
												{this.props.app.state.cantique.cds.length > 1 && "s"}
												quel
												{this.props.app.state.cantique.cds.length > 1 && "s"} ce
												chant a paru
											</h4>
											<div className="pt-3 row justify-content-center">
												{this.props.app.state.cantique.cds.map(
													cd =>
														cd.media.isPublic && (
															<div
																key={cd.media.id}
																className="col-6 col-md-5 mb-6"
															>
																<Link
																	to={"/media/" + cd.media.id}
																	style={{ color: "white" }}
																>
																	<div className="row">
																		<div className="col-12 text-center">
																			<img
																				className="w-75"
																				src={
																					cd.media.couverture.thumbnail_std
																						.publicLink
																				}
																				alt=""
																			/>
																		</div>
																		<div className="col-12 text-center mt-1">
																			{cd.media.titre} piste {cd.rang}
																		</div>
																	</div>
																</Link>
															</div>
														)
												)}
											</div>
										</div>
									)}

								{this.props.app.state.cantique.recueils &&
									this.props.app.state.cantique.recueils.length > 0 && (
										<div className="col-12 col-md-6 pt-5 text-center">
											<h4 className="text-uppercase">
												Recueil
												{this.props.app.state.cantique.recueils.length > 1 &&
													"s"}{" "}
												dans le
												{this.props.app.state.cantique.recueils.length > 1 &&
													"s"}
												quel
												{this.props.app.state.cantique.recueils.length > 1 &&
													"s"}{" "}
												ce chant a été édité
											</h4>
											<div className="pt-3 row justify-content-center">
												{this.props.app.state.cantique.recueils.map(
													recueil =>
														recueil.media.isPublic && (
															<div key={recueil.media.id} className="col-6">
																<Link
																	to={"/media/" + recueil.media.id}
																	style={{ color: "white" }}
																>
																	<div className="row">
																		<div className="col-12 text-center">
																			<img
																				className="w-50"
																				src={
																					recueil.media.couverture.thumbnail_std
																						.publicLink
																				}
																				alt=""
																			/>
																		</div>
																		<div className="col-12 text-center mt-1">
																			{recueil.media.titre} n°{recueil.rang}
																		</div>
																	</div>
																</Link>
															</div>
														)
												)}
											</div>
										</div>
									)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(MediaCantique);
