import React from "react";
import { withTranslation } from "react-i18next";

import Menu from "./Menu/Menu";

class MenuWrapper extends React.Component {
	render() {
		return (
			<Menu
				items={[
					{
						rank: 1,
						link: "/cantiques/album",
						name: "albums",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 2,
						link: "/cantiques/recueil",
						name: "recueils",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 3,
						link: "/cantiques/cantique",
						name: "cantiques & partitions",
						roles: ["ROLE_PUBLIC"]
					},

					{
						rank: 4,
						link: "/videos",
						name: "vidéos",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 9,
						link: "/webradio",
						name: "webradio",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 10,
						link: "/contact",
						name: "contact",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 11,
						name: "mission timothée",
						link: "https://www.missiontimothee.fr",
						roles: ["ROLE_PUBLIC"]
					}
				]}
			/>
		);
	}
}

export default withTranslation()(MenuWrapper);
