// Libraries
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import $ from "jquery";
import Footer from "./Footer/Footer";
import BarrePolitique from "./PolitiqueConfidentialite/BarrePolitique";
import HeaderAndRoute from "./HeaderAndRoute";
import Home from "./Home";
import Login from "./Login/Login";
import Inscription from "./Login/Inscription";
import MonCompte from "./Login/MonCompte";
import CompteOublie from "./Login/CompteOublie";
import ChangerMotDePasse from "./Login/ChangerMotDePasse";
import ConnectLink from "./Login/ConnectLink";
import Contact from "./Contact";
import PolitiqueConfidentialite from "./PolitiqueConfidentialite/PolitiqueConfidentialite";
import { AchatMedia } from "./AchatMedia/AchatMedia";
import { Code } from "./Code/Code";
import ElasticCantiques from "./ElasticCantiques/ElasticCantiques";

import { PrivateRoute, LoginRoute } from "./Login/PrivateHeaders";
import "@mitim/react-mitim/dist/styles.css";
import { MediaFactory } from "./Media";
import { ApplicationMobile } from "./ApplicationMobile/ApplicationMobile";

import { api, Storage } from "@mitim/react-mitim";

// CSS
import "../styles/App.scss";
import "../styles/Font.scss";
import "../styles/Media.scss";

import Video from "./Video";
import Webradio from "./Webradio";
import { ActivationQrCode } from "./ActivationQrCode/ActivationQrCode";
import AffichageCantiques from "./AffichageCantiques/AffichageCantiques";

$(document).ready(function() {
	/* ---------------------------------------------- /*
		* Scroll top
		/* ---------------------------------------------- */

	$(window).scroll(function() {
		if ($(this).scrollTop() > 100) {
			$(".scroll-up").fadeIn();
		} else {
			$(".scroll-up").fadeOut();
		}
	});

	$('a[href="#totop"]').click(function() {
		$("html, body").animate(
			{
				scrollTop: 0
			},
			"slow"
		);
		return false;
	});

	function receiveMessage(event) {
		// Faisons-nous confiance à l'expéditeur de ce message ?  (il pourrait être
		// différent de la fenêtre que nous avons ouverte au départ, par exemple).
		if (event.origin !== "https://storage.timothee.fr") return;
		// console.log(event.data);
		if (event.data.token !== null) {
			// token existe ?

			if (event.data.cookies === "true") {
				localStorage.setItem("cookies", "true");
			}
			const user = Storage.getUserParsed();
			if (user !== null && user.token === event.data.token) {
				console.log("le token est le même");
			} else {
				console.log("le token est différent");
				loadUserByToken(event.data.token);
			}
		} else {
			// si le token n'existe pas
			console.log("pas de token !");
			const user = Storage.getUserParsed();
			if (user !== null) {
				let packet = {
					methode: "postUser",
					user: user
				};
				iframeStorage.postMessage(packet, "https://storage.timothee.fr");
			}
		}
	}
	window.addEventListener("message", receiveMessage, false);

	var iframeStorage = document.getElementById("iframeStorage").contentWindow; // on récupere l'iframe du storage.timothee.fr
	let packet = {
		methode: "getToken"
	};
	iframeStorage.postMessage(packet, "https://storage.timothee.fr");
});

export default class App extends React.Component {
	render() {
		return (
			<Router>
				<div className="App">
					<Switch>
						{/* DIVERS *************************************************** */}
						{/* cookies */}
						<HeaderAndRoute
							path="/cookies"
							component={PolitiqueConfidentialite}
							slide={{
								template: 2,
								image: "Contact.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Politique de confidentialité",
								descriptionMeta: "Politique de confidentialité"
							}}
						/>

						<Route path="/media/:id" component={MediaFactory} />

						{/* Connexion */}
						<LoginRoute
							path="/login"
							component={Login}
							titre="menu.login"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Page d'authentification",
								descriptionMeta: "Page d'authentification"
							}}
						/>

						{/* Ajout pour avecdescrisdejoie */}
						<HeaderAndRoute
							path="/videos"
							component={Video}
							slide={{
								template: 2,
								image: "mer2.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Vidéos",
								descriptionMeta: "Liste des vidéos"
							}}
						/>

						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_CONNECTED"]}
							path="/achat-media/:idMedia"
							component={AchatMedia}
							slide={{
								template: 2,
								image: "mer2.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Paiement",
								descriptionMeta: "Achat"
							}}
						/>

						<HeaderAndRoute
							path="/webradio"
							component={Webradio}
							slide={{
								template: 2,
								image: "reuDirect.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Webradio",
								descriptionMeta: "webradio"
							}}
						/>

						{/* <HeaderAndRoute
							path="/cantiques"
							render={({ match, location }) => (
								<Search
									location={location}
									rootPath={match.path}
									defaultCategory="toutCantique"
									categories={["toutCantique", "cantique", "album", "recueil"]}
								/>
							)}
							slide={{
								template: 2,
								image: "guitareFolk.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Cantiques",
								descriptionMeta: "Cantiques"
							}}
						/> */}
						<HeaderAndRoute
							path="/cantiques/:type"
							component={AffichageCantiques}
							slide={{
								template: 2,
								image: "guitareFolk.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Cantiques",
								descriptionMeta: "Cantiques"
							}}
						/>

						<LoginRoute
							path="/inscription"
							component={Inscription}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Créer un compte",
								descriptionMeta: "Créer un compte"
							}}
						/>
						<HeaderAndRoute
							path="/mon-compte"
							component={MonCompte}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Mon compte",
								descriptionMeta: "Créer un compte"
							}}
						/>
						<LoginRoute
							path="/compte-oublie"
							component={CompteOublie}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Informations de compte oubliées",
								descriptionMeta: "Informations de compte oubliées"
							}}
						/>
						<HeaderAndRoute
							path="/changer-mot-de-passe/:token"
							component={ChangerMotDePasse}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Changement de mot de passe",
								descriptionMeta: "Changement de mot de passe"
							}}
						/>

						<HeaderAndRoute
							path="/connexion/:token"
							component={ConnectLink}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Connexion",
								descriptionMeta: "Connexion"
							}}
						/>

						{/* ******************************************************************* */}

						{/* code d'activation */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_CONNECTED"]}
							path="/cde/:token"
							component={Code}
							slide={{
								template: 2,
								image: "mer.jpg",
								// icone: "icone_maison.svg",
								h1: "Code d'activation",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/qrCode/:token"
							component={ActivationQrCode}
							slide={{
								template: 2,
								image: "mer.jpg",
								// icone: "icone_maison.svg",
								h1: "Retrait CD",
								// h2: "EN FRANCE | À L’ÉTRANGER",
								h3: "",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/elastic"
							component={ElasticCantiques}
							slide={{
								template: 2,
								image: "mer.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Cantiques",
								descriptionMeta: ""
							}}
						/>

						<HeaderAndRoute
							path="/application-mobile"
							component={ApplicationMobile}
							slide={{
								template: 2,
								image: "mer.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Application mobile",
								descriptionMeta: ""
							}}
						/>

						{/* ************************************************************ */}

						{/* CONTACT */}
						<HeaderAndRoute
							path="/contact"
							component={Contact}
							slide={{
								template: 2,
								image: "Contact.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Contact",
								descriptionMeta:
									"Vous pouvez nous contacter par voie postale, par téléphone ou en envoyant un message via le formulaire."
							}}
						/>
						{/* HOME */}
						<HeaderAndRoute
							path="/"
							slide={{
								template: 4,
								image: "cdj-home.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Avec Des Cris De Joie",
								descriptionMeta:
									"Avec Des Cris De Joie - Mission Timothée - Chorales, cantiques, chants de louanges"
							}}
							component={Home}
						/>
					</Switch>
					<Footer />
					<BarrePolitique />
					<div className="scroll-up">
						<a href="#totop">
							<i className="fa fa-angle-double-up" />
						</a>
					</div>
				</div>
			</Router>
		);
	}
}

export const loadUserByToken = token => {
	fetch(`${api.timediaUrl}/public/getUser`, {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + token
		},
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			var authorities = new Array();
			json.data.authorities.forEach(function(authority) {
				authorities.push(authority.name);
			});
			Storage.setAuthoritiesJson(JSON.stringify(authorities));
			localStorage.setItem("user", JSON.stringify(json.data));
			document.location.reload();
		});
	});
};
