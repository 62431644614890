import React from "react";
import Page from "../Page";
import { withTranslation } from "react-i18next";

class Webradio extends Page {
	render() {
		return (
			<div className="container reunionsDirect">
				<div className="mt-4 mb-5">
					{/* <h2 className="text-center mb-5">Webradio Timothée</h2> */}
					<h1 className="contenu-h1 pt-5 pb-5 text-uppercase">
						Webradio Timothée
					</h1>
					<p>
						La Radio Web de la Mission Timothée fonctionne toute la journée. Son
						but est la diffusion de la Parole de Dieu sous forme de chants
						chrétiens, à tous et à tous moments.
					</p>
					<p>
						Le programme diffuse toute la journée une grande variété de
						cantiques chantés en chorale ou en assemblée.
						<br />
						Ce nouveau service nous offre la possibilité de partager de manière
						plus étendue tout ce que Dieu nous a donné, et de répondre ainsi à
						cet ordre : « ne retiens pas ! ». Que l’écoute de Sa parole par les
						chants nourrisse la foi de tous ceux qui l’aiment et le cherchent.
					</p>
					<div className="mt-5">
						<ul className="liste">
							<li>
								<a href="https://radio.timothee.fr/radio.mp3.m3u">
									Télécharger le lien
								</a>{" "}
								et écouter avec votre lecteur
							</li>
							<li>
								<a href="https://storage.timothee.fr/ftp/media/doc/Webradio-Android.pdf">
									Télécharger la documentation Android
								</a>
							</li>
							<li>
								<a href="https://storage.timothee.fr/ftp/media/doc/Webradio-Iphone.pdf">
									Télécharger la documentation iphone
								</a>
							</li>
						</ul>
					</div>

					<div className="text-center mt-5 mb-5">
						<audio controls src="https://radio.timothee.fr/radio.mp3">
							{" "}
						</audio>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(Webradio);
