import { api } from "@mitim/react-mitim";

export const cle_stripe_taj_publique =
	"pk_live_51GxtKoLVOUtYxwngPkSWfHmkXpkb9BCSgJPggB5Yg1EnwBQTHjWlHueMukroZZPSO71JfUzXT507QTiTzcQX4YRQ006DKa651M";
// export const cle_stripe_taj_publique = "pk_test_on4y898bTfQKFPOOGeJKTXeu00qQoKTPjQ"; // test compte nolot
export const cle_stripe_mt_publique =
	"pk_live_51Gxv1lEEkSQFkSB7IPVLz1ewrHMANYEz1tG5hzKPsbEI6g1IBf2EDNcYretyayodEy7noUgbxFXyvImmc9i7Abtb00oboK6ApS";
// export const cle_stripe_mt_publique = "pk_test_on4y898bTfQKFPOOGeJKTXeu00qQoKTPjQ"; // test compte nolot
export const cle_stripe_achat_test =
	"pk_test_on4y898bTfQKFPOOGeJKTXeu00qQoKTPjQ"; // test compte nolot
export const cle_stripe_achat_cocebal =
	"pk_live_51Htb74E8tS6wz08wXk49fOzzuxEmnioN6fxHKAfF9Zh9IQ5QwssKyC38Achy74yQswK23owKIvsuoG0ajdfDJVxq00lRMxRu2W"; // cle prod cocebal

export const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";

export const urlPublicationLJC = "https://www.librairiecalvin.fr/article/";

export const getKeyValuePublique = (cle, callback) => {
	const tokenPublic = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
	const defaultHeaders = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: "Basic " + tokenPublic
	};
	fetch(`${api.timediaUrl}/keyvaluepublics/${cle}`, {
		headers: defaultHeaders,
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			if (json.data !== null) {
				const val = JSON.parse(json.data.valeur);
				console.log(val);
				callback(val);
			}
		});
	});
};
