import React from "react";
import "./Code.scss";
import Modal from "react-bootstrap/Modal";
import { Storage, api } from "@mitim/react-mitim";

export class Code extends React.Component {
	state = {
		showModal: false,
		paymentSuccess: false,
		token: ""
	};

	componentDidMount() {
		this.setState({ token: this.props.match.params.token });
	}

	handleChangeText = name => event => {
		this.setState({
			token: event.target.value
		});
	};

	inputText(label, name, type, nbrCol) {
		return (
			<div className={"form-group col-md-" + nbrCol}>
				<label htmlFor="" className="col-form-label ">
					{label}
				</label>
				<input
					style={{ backgroundColor: "#f6f6f6", color: "#000" }}
					type={type}
					value={this.state.token}
					onChange={this.handleChangeText(name)}
					className="form-control"
					id={"Id" + name}
					placeholder={label + "*"}
					name={name + "Name"}
					required
				/>
			</div>
		);
	}

	handleSubmitFormulaire = event => {
		event.preventDefault();

		// const user = JSON.parse(localStorage.getItem("user"));
		const user = Storage.getUserParsed();

		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + user.token
		};

		// fetch(
		// 	`http://127.0.0.1:8084/timedia/public/activateTokenLibrairie/${this.state.token}`,
		// 	{
		fetch(
			`${api.timediaUrl}/public/activateTokenLibrairie/${this.state.token}`,
			{
				method: "GET",
				headers: defaultHeaders
			}
		)
			.then(response => {
				if (response.status !== 200) {
					console.log("Error: " + response.status);
					return;
				}
				response.json().then(data => {
					if (data.success) {
						this.setState({
							messageModal: (
								<>
									Votre code à bien été activé, vous allez être redirigé vers
									votre achat.
									<br />
									Merci
								</>
							),
							showModal: true,
							paymentSuccess: true
						});
					} else {
						this.setState({
							messageModal: (
								<>
									Votre code n'est pas valide ou a déjà été activé.
									<br />
									Merci
								</>
							),
							showModal: true,
							paymentSuccess: false
						});
					}
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	render() {
		const BoiteDialogue = () => (
			<Modal
				show={this.state.showModal}
				onHide={() =>
					this.setState({ showModal: false }, () =>
						this.state.paymentSuccess
							? this.props.history.push({
									pathname: "/media/1796/Avec-Des-Cris-de-Joie-n°19",
									state: { from: "/" }
							  })
							: window.location.reload()
					)
				}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() =>
							this.setState({ showModal: false }, () =>
								this.state.paymentSuccess
									? this.props.history.push({
											pathname: "/media/1796/Avec-Des-Cris-de-Joie-n°19",
											state: { from: "/" }
									  })
									: window.location.reload()
							)
						}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="text-center">{this.state.messageModal}</h4>
				</Modal.Body>
			</Modal>
		);
		return (
			<div className="container pt-5 pb-5 pl-md-5 pr-md-5">
				<BoiteDialogue />
				<h2 className="text-center pt-5 pb-5">
					Activation du code de téléchargement
				</h2>
				<form
					onSubmit={this.handleSubmitFormulaire}
					method="POST"
					className="pl-md-5 pr-md-5"
				>
					{/* <form> */}

					<div className="form-row">
						{this.inputText("", "token", "text", "12")}
					</div>

					<div className="text-right pt-2">
						<button
							type="submit"
							// onClick={this.handleSubmitFormulaire}
							className="btn btn-primary center-block pl-5 pr-5"
						>
							Activer
						</button>
					</div>
				</form>
			</div>
		);
	}
}
