export const AVIS = [
	{
		nom: "jeromeGVT",
		date: "22/04/2021",
		titre: "Tout les albums au même endroit",
		texte:
			"Une bonne application pour avoir tous les albums de la Mission Timothée au même endroit, facile d’utilisation et qui permet d’écouter même quand le téléphone verrouillé. Merci"
	},
	{
		nom: "Scorgne",
		date: "23/04/2021",
		titre: "Super application",
		texte:
			"Super application pour écouter des chants bibliques édifiants. L’avantage est de pouvoir le faire hors connexion, en voyage etc..."
	},
	{
		nom: "SPuis",
		date: "27/04/2021",
		titre: "Super appli",
		texte:
			"Super application Merci bcp pour tout ce travail qui nous permet de pouvoir écouter ces beaux cantiques à n’importe quel moments"
	},
	{
		nom: "tzbdso",
		date: "28/04/2021",
		titre: "Appli pratique",
		texte: "Excellente application, très fonctionnelle! Merci!"
	},
	{
		nom: "Nenette2708",
		date: "29/04/2021",
		titre: "Au top",
		texte:
			"Super application avec tous les albums de la Mission Timothee avec en plus les albums de chants d’enfants et l’album de chants Bulgare."
	}
];
