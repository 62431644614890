// Librairies
import React from "react";
import Page from "../Page";

export default class MediaPage extends Page {
	// state = {
	// 	media: '',
	// 	auteurs: [],
	// 	fichiers: [],
	// 	livreBibles: [],
	// 	mediaMedias: [],
	// 	videoYoutubes: [],
	// 	cantique: {},
	// 	cantiqueListen: '',
	// 	id: '0',
	// 	rangMediaMedia: '0',
	// 	classementMediaMedia: '0'
	// };
	// componentDidMount() {
	// 	super.componentDidMount();
	// 	// getMedia(this.props.match.params.id, this, () => {
	// 	getMedia(this.props.idMedia, this, () => {
	// 		if (this.state.media.mediaType.id === 2) {
	// 			// Cantique
	// 			this.setState({
	// 				cantique: {
	// 					cds: this.state.mediaMedias.filter(
	// 						m => m.media && m.media.mediaType.id === 8
	// 					),
	// 					recueils: this.state.mediaMedias.filter(
	// 						m => m.media && m.media.mediaType.id === 10
	// 					) // TODO change to real ID
	// 				}
	// 			});
	// 			let audioFichier = this.state.fichiers.filter(
	// 				fichier =>
	// 					fichier.fichier &&
	// 					fichier.fichier.fichierContentType.fichierType.id === 4
	// 			);
	// 			if (audioFichier.length >= 1) {
	// 				this.setState({
	// 					cantiqueListen: audioFichier[0].fichier.link,
	// 					id: audioFichier[0].fichier.id,
	// 					rangMediaMedia: audioFichier[0].rang
	// 				});
	// 			}
	// 		}
	// 	});
	// }
}
