import React from "react";
import Page from "../Page";
import "./AffichageCantiques.scss";
import { NavLink, Link } from "react-router-dom";
import { titleCase } from "../../utils/functions";
import ElasticCantiques from "../ElasticCantiques/ElasticCantiques";
import AsyncImage from "../AsyncImage";
import { api, auth, Storage } from "@mitim/react-mitim";

export default class AffichageCantiques extends Page {
	state = {
		acces: true,
		type: "",
		medias: [],
		vivier: []
	};

	componentDidMount() {
		super.componentDidMount();
		this.setState({ type: this.props.match.params.type });
		if (
			this.props.match.params.type != "cantique" &&
			this.props.match.params.type != "vivier"
		) {
			this.loadAllMedias();
		}
		if (this.props.match.params.type === "vivier") {
			this.loadVivier();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.type !== prevProps.match.params.type) {
			console.log("update");
			this.setState({ type: this.props.match.params.type });
			if (
				this.props.match.params.type != "cantique" &&
				this.props.match.params.type != "vivier"
			) {
				this.loadAllMedias();
			}
			if (this.props.match.params.type === "vivier") {
				this.loadVivier();
			}
		}
	}

	loadVivier() {
		const user = Storage.getUserParsed();
		const token = user.token;
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + token
		};
		fetch(`${api.timediaUrl}/public/vivier`, {
			method: "GET",
			headers: defaultHeaders
		})
			.then(res => {
				return res.json();
			})
			.then(data => {
				console.log(data.data);
				this.setState({ vivier: data.data });
			});
	}

	loadAllMedias() {
		const token = "cHVibGljOjk4SE85SE9JVUdPSTk4SA==";
		let data = {
			category: this.props.match.params.type,
			pageSize: 100
		};
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + token
		};
		fetch(`${api.timediaUrl}/public/medias`, {
			method: "POST",
			headers: defaultHeaders,
			body: JSON.stringify(data)
		})
			.then(res => {
				return res.json();
			})
			.then(data => {
				console.log(data.data);
				this.setState({ medias: data.data });
			});
	}

	render() {
		let classes = {
			filter: "col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2",
			result: "col-12 col-md-8 col-lg-9 col-xl-9 col-xxl-10",
			pagination: "pt-5"
		};
		const CATEGORIES = [
			{ type: "album", nom: "Albums", authority: "ROLE_PUBLIC" },
			{ type: "recueil", nom: "Recueils", authority: "ROLE_PUBLIC" },
			{ type: "cantique", nom: "Cantiques", authority: "ROLE_PUBLIC" },
			{ type: "vivier", nom: "Vivier", authority: "ROLE_VIVIER" }
		];
		const categories = CATEGORIES.map(
			cat =>
				((auth.hasRoles(["ROLE_VIVIER"]) && cat.authority === "ROLE_VIVIER") ||
					cat.authority === "ROLE_PUBLIC") && (
					<div className="col-auto category pl-5" key={cat.type}>
						<NavLink exact to={"/cantiques/" + cat.type}>
							{titleCase(cat.nom)}
						</NavLink>
					</div>
				)
		);

		const Element = props => (
			<div className="col-6 col-lg-6 col-xl-3 text-center mt-5 result">
				<Link
					to={
						"/media/" +
						props.data.id +
						"/" +
						props.data.titre.split(" ").join("-")
					}
				>
					<div className="img-zoom mx-auto">
						<AsyncImage
							source={props.data.couverture.thumbnail_std.publicLink}
							placeholderColor="#ececec"
							cssClass="mx-auto result-img async-img-result"
							className="img-fluid pl-3 pr-3"
						/>
					</div>
					<h6 className="mt-2 result-title">{props.data.titre}</h6>
				</Link>
			</div>
		);

		const ElementVivier = props => (
			<div className="col-12 mt-1 result" style={{ backgroundColor: "#fff" }}>
				<div className="row pl-md-3">
					<div className="col-12 mt-1 result affichageCantiques">
						<Link
							to={`/media/${props.data.id}/${props.data.titre}`}
							className="lien"
						>
							<div>
								<span
									className="text-white pl-3 pr-3 ft-lato-regular pt-2 pb-1"
									style={{
										fontSize: "0.8em",
										backgroundColor: "rgb(124, 180, 215)"
									}}
								>
									<>CANTIQUE</>
								</span>
							</div>
							<div className="select pr-2">
								<div
									className="pt-2 text-black ft-alegreya-sans-light pb-2"
									style={{ fontSize: "1em" }}
								>
									<span style={{ verticalAlign: "bottom" }}>
										<span className="pb-5">{props.data.titre}</span>
									</span>
									<span style={{ float: "right" }}></span>
								</div>
							</div>
						</Link>
						<hr className="pt-2 pl-2" />
					</div>
				</div>
			</div>
		);

		const AffichageDatas = () => (
			<>
				{(this.state.type === "recueil" || this.state.type === "album") && (
					<>
						{this.state.medias &&
							this.state.medias.map(elem => (
								<Element key={elem.id} data={elem} />
							))}
					</>
				)}
				{this.state.type === "vivier" && (
					<>
						<div className="col-12 mt-5"></div>
						{this.state.vivier &&
							this.state.vivier.map(elem => (
								<ElementVivier key={elem.id} data={elem} />
							))}
					</>
				)}
			</>
		);

		return (
			<>
				<div className="container-fluid">
					<div className="row align-items-center categories-navbar">
						<div className={classes.filter + " mt-3 my-md-3"}></div>

						<div className={classes.result}>
							<div className="row categories my-3">{categories}</div>
						</div>
					</div>

					{this.state.type != "cantique" ? (
						<>
							<div className="row">
								<div className={classes.filter + " filter-list"}></div>
								<div
									className={classes.result}
									style={{ backgroundColor: "#fff" }}
								>
									<div className="row pb-5">
										<AffichageDatas />
										<p>
											<br />
											<br />
											<br />
											<br />
											<br />
										</p>
									</div>
								</div>
							</div>
						</>
					) : (
						<ElasticCantiques />
					)}
				</div>
			</>
		);
	}
}
